import React, { useState } from "react";
import { Tabs } from ".";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const TabContainer = ({isMenuOpen}:any) => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div
    className={`pr-[1rem] mb-4 ${
      isMenuOpen ? "pl-[300px]" : "pl-[76px]"
    } duration-500`}
  >
      <div className="flex flex-col items-start ">
        {/* <div className="w-1/5 p-4 bg-table-light  border border-[#DCDBEE] rounded-lg shadow-lg space-y space-y-4 text-md font-medium text-dark dark:bg-table-dark dark:text-white md:me-4 mb-4 md:mb-0">
          {Tabs.map((tab, index) => (
            <Link
              key={index}
              className={`block w-full p-2 mb-1 rounded-lg  ${
                pathname === tab.path
                  ? "bg-[#E0ECF5] dark:bg-[#293344]"
                  : "hover:bg-[#F5F5F5] dark:hover:bg-sub-table-light"
              }`}
              to={tab.path}
              onClick={() => setActiveTab(index)}
            >
              {tab.label}
            </Link>
          ))}
        </div> */}
        <div className="my-4">
          <Breadcrumbs
          breadcrumbs={["User Profile"]}
          icon={""}
        />
        </div>
        <div className="p-4 border border-[#DCDBEE] shadow-lg bg-table-light  text-medium text-white dark:text-gray-400 dark:bg-table-dark rounded-lg w-full shadow-shadow-md">
          {React.createElement(Tabs[activeTab].content)}
        </div>
      </div>
    </div>
  );
};

export default TabContainer;
