// React
import React, { useEffect, useState } from "react";
// Third party
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  Column,
} from "react-table";
// API
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Images
import Arrow from "../../assets/svg/arrow-down-file-list-table.svg";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import copyLink from "../../assets/svg/copy_link.svg";
import edit from "../../assets/svg/edit.svg";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import SortIconAsc from "../../assets/svg/sort-icon-asc.svg";
import SortIconDesc from "../../assets/svg/sort-icon-desc.svg";
// Components
import { Pagination } from "../Pagination/Pagination";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  nextNotesListPage,
  previousNotesListPage,
  setNotesListPageIndex,
} from "../../features/notesListPagination";
// Types
import { PaginationActionType } from "../../types/pagination-types";
import {
  ColumnTable,
  HeaderGroup,
} from "../../interfaces/pages/variedInterfaces";
import Loader from "../Loader";

interface TableProps {
  columns: Column[];
  additionalDataKey: string;
  openModal?: ((value: any) => void) | undefined;
  caption?: string;
  search?: string;
  loading: boolean;
  fetchData: (pageIndex: number, pageSize: number) => Promise<any>;
  typeForPagination?: PaginationActionType;
  isMenuOpen: boolean;
}

const Table: React.FC<TableProps> = ({
  columns,
  fetchData,
  search,
  loading,
  typeForPagination,
  isMenuOpen,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [totalElement, setTotalElement] = useState(0);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleNextPage = () => {
    dispatch(nextNotesListPage());
  };

  const handlePreviousPage = () => {
    dispatch(previousNotesListPage());
  };

  const handleSetPage = (index: number) => {
    dispatch(setNotesListPageIndex(index));
  };

  const paginationStateMap: Record<PaginationActionType, keyof RootState> = {
    setErrorsListPageIndex: "errorsListPagination",
    setFilesListPageIndex: "filesListPagination",
    setFundamentalListPageIndex: "fundamentalListPagination",
    setMnaListPageIndex: "mnaListPagination",
    setNotesForMnaPageIndex: "notesForMnaPagination",
    setNotesListPageIndex: "notesListPagination",
    setRegulatoryListPageIndex: "regulatoryListPagination",
    setNotesForFundamentalPageIndex: "notesForFundamental",
  };

  const selectedPaginationSlice = paginationStateMap[typeForPagination!];

  const pageIndex = useSelector((state: RootState) => {
    const paginationState = state[selectedPaginationSlice];
    if (paginationState && "pageIndex" in paginationState) {
      return paginationState.pageIndex;
    }
    return 0;
  });
  const {
    headerGroups,
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state,
    pageCount,
    setPageSize,
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualSortBy: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
    } as any,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const { pageSize } = state;

  useEffect(() => {
    setPageSize(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      if (loading) return;
      try {
        const result = await fetchData(pageIndex, pageSize);
        setData(result.content);
        setControlledPageCount(Math.ceil(result.page.totalPages));
        setTotalElement(Math.ceil(result.page.totalElements));
      } catch (error) {
        expiredTokenValidation(error);
      }
    };

    fetchDataAndUpdateState();
  }, [fetchData, pageIndex, pageSize, search]);

  const sanitizedContent = rows?.original?.content
    ? DOMPurify.sanitize(rows?.original?.content)
    : "";

  const MAX_DOMAINS_TO_SHOW = 6;

  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  );

  const [activeSortColumn, setActiveSortColumn] = useState(null);

  const handleSortToggle = (column: ColumnTable) => {
    const { canSort, toggleSortBy, isSortedDesc } = column;

    if (canSort) {
      toggleSortBy(!isSortedDesc, false);
    }
  };


  const toggleExpand = (rowId: string) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const handleColumnClick = (column: any) => {
    if (
      column.id === "dealSourceCategory" ||
      column.id === "Links" ||
      column.id === "username" ||
      column.id === "requestUri" ||
      column.id === "requestMethod" ||
      column.id === "action"
    ) {
      return;
    } else {
      setActiveSortColumn(column.id);
      handleSortToggle(column);
    }
  };

  return (
    <React.Fragment>
      {/* Table */}
      <table
        {...getTableProps()}
        className="min-w-full"
        data-testid="note-card"
      >
        <tbody {...getTableBodyProps()}>
          <tr className="">
            <td className="">
              {rows.map((row: any) => {
                const isExpanded = expandedRows[row.id];
                prepareRow(row);
                return (
                  <React.Fragment key={row.id}>
                    <div className="w-full shadow-shadow-md rounded-[16px] last:mb-[70px] mb-4 ">
                      <div
                        className="container-fluid mx-auto flex items-start justify-center
                    
                        "
                      >
                        <div className="w-full ">
                          <div className="mx-auto w-full  dark:bg-gray-800 border border-[#E0DFE5] rounded-2xl ">
                            <div className="bg-[#F9F9FB] justify-between  p-4 flex flex-col lg:flex-row items-start rounded-t-2xl lg:items-center dark:bg-sub-table-light dark:text-white">
                              <div className="flex flex-col mt-3 lg:mt-0 md:flex-row items-start md:items-center ">
                                {row.original.domains?.length > 0 && (
                                  <>
                                    {row.original.domains
                                      .slice(0, MAX_DOMAINS_TO_SHOW)
                                      .map((domain: any, index: number) => {
                                        if (!domain.name) return null;
                                        const linkPath = `/${
                                          domain.domainName?.toLowerCase() ||
                                          "unknown"
                                        }/${domain.entityId || "unknown"}`;
                                        return (
                                          <h2
                                            key={index}
                                            data-testid="note-header"
                                          >
                                            <Link
                                              to={linkPath}
                                              className="text-base-medium text-blue-900"
                                            >
                                              {domain.name}
                                            </Link>
                                            {index !==
                                            Math.min(
                                              row.original.domains.length,
                                              MAX_DOMAINS_TO_SHOW
                                            ) -
                                              1 ? (
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html: ",&nbsp;",
                                                }}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </h2>
                                        );
                                      })}
                                    {row.original.domains.length >
                                      MAX_DOMAINS_TO_SHOW && (
                                      <p className="text-sm text-black">
                                        ...{" "}
                                        {/* {row.original.domains.length -
                       MAX_DOMAINS_TO_SHOW}{" "}
                     more */}
                                      </p>
                                    )}
                                  </>
                                )}
                              </div>

                              <div className="flex gap-2 flex-col mt-3 lg:mt-0 md:flex-row  items-start md:items-center">
                                {row.original?.tags?.map(
                                  (tag: any, index: number) => (
                                    <div
                                      data-testid="note-tag"
                                      key={index}
                                      className="bg-[#DBE9FF] text-sm-normal text-blue-900 leading-3 py-2 px-3 rounded-full"
                                    >
                                      {tag.tag}
                                    </div>
                                  )
                                )}

                                <button
                                  data-testid="note-copy"
                                  className="bg-[#DBE9FF] w-[30px] h-[30px] rounded-full flex items-center justify-center"
                                >
                                  <img
                                    className="w-auto"
                                    src={copyLink}
                                    alt="copyLink"
                                  />
                                </button>
                                <button
                                  className="text-secondary font-semibold text-base capitalize underline float-right"
                                  onClick={() =>
                                    navigate(`/notes/${row.original.id}/edit`)
                                  }
                                  data-testid="edit-note"
                                >
                                  <div className="rounded-full p-[8px] border border-none bg-[#FFF4CA] cursor-pointer">
                                    <img
                                      className="h-[14px] w-[14px]"
                                      src={edit}
                                      alt="edit note"
                                    />
                                  </div>
                                </button>
                              </div>
                            </div>
                            <div className="justify-between flex flex-col lg:flex-row items-start lg:items-center py-[10px] pl-[10px] pr-[13px]">
                              <div className="w-full lg:w-1/2 pr-0 ">
                                <Link
                                  data-testid="note-title"
                                  to={`/notes/${row.original.id}`}
                                  className="cursor-pointer font-medium text-primary-dark dark:text-primary-light text-base"
                                >
                                  {row.original.title}
                                </Link>
                                <p
                                  data-testid="note-source"
                                  className=" text-base text-[#888888] dark:text-primary-light font-medium"
                                >
                                  {row.original.sourceType}
                                </p>
                              </div>
                              <div className="w-full lg:w-1/2">
                                <div className="flex justify-end">
                                  <span className="group relative cursor-pointer">
                                    <div className="absolute bottom-0   translate-x-[-105%] translate-y-[37%] hidden group-hover:block min-w-[330px]">
                                      <div className="bg-[#fff] text-[#888888]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  px-[12px] pt-[12px] pb-[12px] rounded-[10px] border border-[#4185EE] ">
                                        <ul className="max-w-md space-y-1 text-dark list-none list-inside dark:text-white">
                                          <li>
                                            Date Published:{" "}
                                            {moment(
                                              row.original.datePublished
                                            ).format("MM/DD/yyyy hh:mm a")}
                                          </li>
                                          <li>
                                            Date Created:{" "}
                                            {moment(
                                              row.original.dateCreated
                                            ).format("MM/DD/yyyy hh:mm a")}{" "}
                                            {row.original.createdBy?.username}
                                          </li>
                                          <li>
                                            Date Modified:{" "}
                                            {moment(
                                              row.original.dateModified
                                            ).format("MM/DD/yyyy hh:mm a")}{" "}
                                            {
                                              row.original.lastModifiedBy
                                                ?.username
                                            }
                                          </li>
                                        </ul>
                                        <div
                                          className="bg-[#4185EE] -translate-x-1/2 translate-y-1/2 absolute bottom-0 right-[-15px] top-[35%]  w-4 h-4 "
                                          style={
                                            {
                                              clipPath:
                                                "polygon(100% 50%, 50% 0, 50% 100%)",
                                            } as React.CSSProperties
                                          }
                                        ></div>
                                      </div>
                                    </div>
                                    <h2
                                      data-testid="note-datePublished"
                                      className=" text-[#888888] text-sm-normal leading-6 lg:mt-0 lg:text-end"
                                    >
                                      {moment(
                                        row.original.datePublished
                                      ).format("MM/DD/yyyy hh:mm a")}
                                    </h2>
                                  </span>
                                </div>
                                {(row.original.sentiment === -1 ||
                                  row.original.sentiment === 0 ||
                                  row.original.sentiment === 1) && (
                                  <h2 className="text-[#888888] text-sm-normal lg:mt-0 lg:text-end">
                                    Sentiment:{" "}
                                    {row.original.sentiment === -1
                                      ? "Negative"
                                      : row.original.sentiment === 0
                                      ? "Neutral"
                                      : "Positive"}
                                  </h2>
                                )}
                              </div>
                            </div>
                            <div className="flex flex-col lg:flex-row ">
                              <div className="flex flex-col lg:flex-row w-full lg:w-11/12 items-start lg:items-center mb-8 lg:mb-0">
                                <div
                                  className=""
                                  style={{ position: "relative" }}
                                >
                                  <div className="pb-[27px] pl-[10px]">
                                    {row.original.content &&
                                      parse(sanitizedContent)}
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Card code block end */}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </td>
          </tr>
        </tbody>
      </table>

      {/* Pagination */}
      <div
        className={`fixed bottom-0 -ml-4 ${
          isMenuOpen ? "w-[calc(100%-278px)]" : "w-[calc(100%-62px)]"
        }  p-4 flex flex-col md:flex-row justify-between bg-grey-50 duration-500`}
        data-testid="pagination-container"
      >
        <div className="mb-4 md:mb-0 md:flex md:justify-between md:items-center">
          <div className="mb-2 md:mb-0">
            <span
              data-testid="pagination-page-info"
              className="text-primary text-sm border-r border-primary pr-[8px]"
            >
              Showing{" "}
              <span className="font-medium">
                {" "}
                {data.length !== 0 && pageIndex * pageSize + 1}
              </span>{" "}
              {data.length !== 0 && "-"}{" "}
              {pageIndex === pageCount - 1 ? (
                <span className="font-medium">
                  {Math.min((pageIndex + 1) * pageSize, totalElement)}
                </span>
              ) : (
                <span className="font-medium">
                  {" "}
                  {Math.min((pageIndex + 1) * pageSize, totalElement)}{" "}
                </span>
              )}
              of <span className="font-medium"> {totalElement}</span>
            </span>
          </div>
          <div className="flex items-center gap-1">
            <div className="text-primary text-sm font-normal ml-[8px]">
              Items per Page
            </div>
            <div className="relative">
              <select
                style={{
                  appearance: "none",
                  backgroundColor: "white",
                  paddingRight: "35px",
                  fontSize: "14px",
                  width: "74px",
                  height: "34px",
                  outline: "none",
                  border: "1px solid #E5E5EA",
                  borderRadius: "8px",
                  transition: "border-color 0.3s ease-in-out",
                  backgroundImage: `url(${Arrow})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "calc(100% - 15px) center",
                  backgroundSize: "16px 16px",
                }}
                value={pageSize}
                onChange={(e) => {
                  const newPageSize = Number(e.target.value);
                  let currentElementIndex = pageIndex * pageSize;
                  let newPageIndex = Math.floor(
                    currentElementIndex / newPageSize
                  );
                  handleSetPage(newPageIndex);
                  setPageSize(newPageSize);
                }}
                className="dark:text-white dark:placeholder:text-white dark:bg-b-secondary-dark dark:border-white border border-[#E5E5EA] rounded-[8px] px-[20px] py-[5px] text-primary appearance-none outline-none cursor-pointer w-[84px] h-[34px]"
              >
                {[10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="flex items-center" data-testid="pagination-buttons">
          <Pagination
            pageCount={pageCount}
            pageIndex={pageIndex}
            typeForPagination={typeForPagination!}
          />
        </div>
        <div className="text-secondary flex items-center mt-4 md:mt-0">
          <div className="flex items-center space-x-4">
            <button
              onClick={handlePreviousPage}
              disabled={pageIndex == 0}
              data-testid="previous-button"
              className={`flex items-center px-4 py-1 rounded-md text-[#181825] text-[14px] border border-transparent 
hover:bg-[#EDF6FF] hover:text-[#3B97FF] hover:border-[#3B97FF] disabled:opacity-50 cursor-pointer`}
            >
              <span className="mr-1">←</span> Previous
            </button>
            <button
              onClick={handleNextPage}
              disabled={pageIndex >= pageCount - 1}
              data-testid="next-button"
              className={`flex items-center px-4 py-1 rounded-md text-[#181825] text-[14px] border border-transparent 
hover:bg-[#EDF6FF] hover:text-[#3B97FF] hover:border-[#3B97FF] disabled:opacity-50 cursor-pointer -mb-[1px]`}
            >
              Next <span className="ml-1">→</span>
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Table;

// {rows.map((row: any) => {
//   const isExpanded = expandedRows[row.id];
// prepareRow(row);
// return (
// <React.Fragment key={row.id}>
//  <div className="w-full shadow-shadow-md mb-2.5 rounded-[16px] last:mb-[70px]">
//    <div className="container-fluid mx-auto flex items-start justify-center overflow-hidden">
//      <div className="w-full overflow-hidden">
//        <div className="mx-auto w-full  dark:bg-gray-800 border border-[#E0DFE5] rounded-2xl overflow-hidden ">
//          <div className="bg-[#F9F9FB] justify-between py-3 pl-[10px] pr-[13px] flex flex-col lg:flex-row items-start rounded-t-2xl lg:items-center dark:bg-sub-table-light dark:text-white">
//            <div className="flex flex-col mt-3 lg:mt-0 md:flex-row items-start md:items-center ">
//              {row.original.domains?.length > 0 && (
//                <>
//                  {row.original.domains
//                    .slice(0, MAX_DOMAINS_TO_SHOW)
//                    .map((domain: any, index: number) => {
//                      if (!domain.name) return null;
//                      const linkPath = `/${
//                        domain.domainName?.toLowerCase() ||
//                        "unknown"
//                      }/${domain.entityId || "unknown"}`;
//                      return (
//                        <h2
//                          key={index}
//                          data-testid="note-header"
//                        >
//                          <Link
//                            to={linkPath}
//                            className="text-primary-dark dark:text-primary-light text-base-semibold"
//                          >
//                            {domain.name}
//                          </Link>
//                          {index !==
//                          Math.min(
//                            row.original.domains.length,
//                            MAX_DOMAINS_TO_SHOW
//                          ) -
//                            1 ? (
//                            <span
//                              dangerouslySetInnerHTML={{
//                                __html: ",&nbsp;",
//                              }}
//                            />
//                          ) : (
//                            ""
//                          )}
//                        </h2>
//                      );
//                    })}
//                  {row.original.domains.length >
//                    MAX_DOMAINS_TO_SHOW && (
//                    <p className="text-sm text-black">
//                      ...{" "}
//                      {/* {row.original.domains.length -
//                        MAX_DOMAINS_TO_SHOW}{" "}
//                      more */}
//                    </p>
//                  )}
//                </>
//              )}
//            </div>
//            <div className="flex gap-2 flex-col mt-3 lg:mt-0 md:flex-row  items-start md:items-center">
//              {row.original?.tags?.map(
//                (tag: any, index: number) => (
//                  <div
//                    data-testid="note-tag"
//                    key={index}
//                    className="bg-[#DBE9FF] text-primary-dark font-medium dark:text-primary-light dark:bg-primary-dark text-sm leading-3 py-2 px-3 rounded-full"
//                  >
//                    {tag.tag}
//                  </div>
//                )
//              )}
//              <button
//                data-testid="note-copy"
//                className="bg-[#DBE9FF] w-[30px] h-[30px] rounded-full flex items-center justify-center"
//              >
//                <img
//                  className="w-auto"
//                  src={copyLink}
//                  alt="copyLink"
//                />
//              </button>
//            </div>
//          </div>
//          <div className="justify-between flex flex-col lg:flex-row items-start lg:items-center py-[10px] pl-[10px] pr-[13px]">
//            <div className="w-full lg:w-1/2 pr-0 ">
//              <Link
//                data-testid="note-title"
//                to={`/notes/${row.original.id}`}
//                className="cursor-pointer font-medium text-primary-dark dark:text-primary-light text-base"
//              >
//                {row.original.title}
//              </Link>
//              <p
//                data-testid="note-source"
//                className=" text-base text-[#888888] dark:text-primary-light font-medium"
//              >
//                {row.original.sourceType}
//              </p>
//            </div>
//            <div className="w-full lg:w-1/2">
//              <div className="flex justify-end">
//                <span className="group relative cursor-pointer">
//                  <div className="absolute bottom-0   translate-x-[-105%] translate-y-[37%] hidden group-hover:block min-w-[330px]">
//                    <div className="bg-[#fff] text-[#888888]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  px-[12px] pt-[12px] pb-[12px] rounded-[10px] border border-[#4185EE] ">
//                      <ul className="max-w-md space-y-1 text-dark list-none list-inside dark:text-white">
//                        <li>
//                          Date Published:{" "}
//                          {moment(
//                            row.original.datePublished
//                          ).format("MM/DD/yyyy hh:mm a")}
//                        </li>
//                        <li>
//                          Date Created:{" "}
//                          {moment(
//                            row.original.dateCreated
//                          ).format("MM/DD/yyyy hh:mm a")}{" "}
//                          {row.original.createdBy?.username}
//                        </li>
//                        <li>
//                          Date Modified:{" "}
//                          {moment(
//                            row.original.dateModified
//                          ).format("MM/DD/yyyy hh:mm a")}{" "}
//                          {
//                            row.original.lastModifiedBy
//                              ?.username
//                          }
//                        </li>
//                      </ul>
//                      <div
//                        className="bg-[#4185EE] -translate-x-1/2 translate-y-1/2 absolute bottom-0 right-[-15px] top-[35%]  w-4 h-4 "
//                        style={
//                          {
//                            clipPath:
//                              "polygon(100% 50%, 50% 0, 50% 100%)",
//                          } as React.CSSProperties
//                        }
//                      ></div>
//                    </div>
//                  </div>
//                  <h2
//                    data-testid="note-datePublished"
//                    className=" text-[#888888] dark:text-primary-light font-medium  text-sm leading-6 lg:mt-0 lg:text-end"
//                  >
//                    {moment(
//                      row.original.datePublished
//                    ).format("MM/DD/yyyy hh:mm a")}
//                  </h2>
//                </span>
//              </div>
//              {(row.original.sentiment === -1 ||
//                row.original.sentiment === 0 ||
//                row.original.sentiment === 1) && (
//                <h2 className="text-[#888888] dark:text-primary-light font-medium text-sm leading-6 lg:mt-0 lg:text-end">
//                  Sentiment:{" "}
//                  {row.original.sentiment === -1
//                    ? "Negative"
//                    : row.original.sentiment === 0
//                    ? "Neutral"
//                    : "Positive"}
//                </h2>
//              )}
//            </div>
//          </div>
//          <div className="flex flex-col lg:flex-row ">
//            <div className="flex flex-col lg:flex-row w-full lg:w-11/12 items-start lg:items-center mb-8 lg:mb-0">
//              <div
//                className=""
//                style={{ position: "relative" }}
//              >
//                <div className="pb-[27px] pl-[10px]">
//                  {row.original.content &&
//                    parse(sanitizedContent)}
//                </div>
//                <div></div>
//              </div>
//            </div>

//            <div className="flex flex-col lg:flex-row ">
//    <div className="flex flex-col lg:flex-row w-full lg:w-11/12 items-start lg:items-center mb-8 lg:mb-0">
//      <div style={{ position: "relative" }}>
//        <button
//          onClick={() => toggleExpand(row.id)}
//          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mb-4"
//        >
//        </button>
//        <div
//          className={
//            isExpanded ? "pb-[27px] pl-[10px]" : "overflow-hidden max-h-24 pb-[27px] pl-[10px]"
//          }
//        >
//          {row.original.content && parse(sanitizedContent)}
//        </div>
//      </div>
//    </div>
//    </div>
//            <div className="flex items-end w-full lg:w-1/12 justify-end mb-[27px] lg:justify-end">
//              <button
//                className="text-secondary font-semibold text-base capitalize underline float-right"
//                onClick={() =>
//                  navigate(`/notes/${row.original.id}/edit`)
//                }
//                data-testid="edit-note"
//              >
//                <div className="rounded-full mr-[13px] p-[8px] border border-none bg-[#FFF4CA] cursor-pointer">
//                  <img
//                    className="h-[14px] w-[14px]"
//                    src={edit}
//                    alt="edit note"
//                  />
//                </div>
//              </button>
//            </div>
//          </div>
//        </div>
//        {/* Card code block end */}
//      </div>
//    </div>
//  </div>
// </React.Fragment>
// );
// })}
