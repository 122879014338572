// mnaService.ts

import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import { getApiData } from "../../../controller/getApiDataConfiguration";
import { ContactDomainApi, SectorDomainApi } from "../../../openapi";


export const fetchSectorList = async (id: string) => {
  try {
    const api = new getApiData();
    const response = await api.getSectorDomainApi(id);
    const filteredOptions = response?.data?.content!.map((item: any) => ({
      value: item.id,
      label: item.name,
    }));
    return filteredOptions;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const fetchMnaData = async (
  id: string,
) => {
  try {
    const api = new getApiData();
    const response: any = await api.getMnaDomainApi2(id, {
      pageSize: 100,
      page: 0
    }); 

    if (
      !isNaN(Number(response.sectorAcquirer)) &&
      response.sectorAcquirer !== null &&
      response.sectorAcquirer !== ""
    ) {
      response.sectorAcquirer = await fetchSectorNameById(
        Number(response.sectorAcquirer)
      );
    }

    if (
      !isNaN(Number(response.sectorTarget)) &&
      response.sectorTarget !== null &&
      response.sectorTarget !== ""
    ) {
      response.sectorTarget = await fetchSectorNameById(
        Number(response.sectorTarget)
      );
    }

    return response;
  } catch (error) {
    expiredTokenValidation(error);
    throw error;
  }
};

export const fetchSectorNameById = async (sectorId: number) => {
  try {
    const api = new SectorDomainApi(apiConfig());
    const response = await api.getSectorById(sectorId);
    return response.data?.name || "";
  } catch (error) {
    expiredTokenValidation(error);
    return "";
  }
};

export const onDeleteContact = async (
  deleteContactId: string,
  setLoading: (value: boolean) => void,
  fetchMnaData: () => Promise<void>,
  setIsContactDeleteModalOpen: (value: boolean) => void
) => {
  try {
    setLoading(true);
    const api = new ContactDomainApi(apiConfig());
    await api.deleteContact(deleteContactId);
    await fetchMnaData();
  } catch (error) {
    console.log(error);
  }
  setLoading(false);
  setIsContactDeleteModalOpen(false);
};

