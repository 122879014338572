//Icons
import ArrowTopRight from '../../../assets/svg/arrow-top-right.svg'
// Third party
import {RawAxiosRequestConfig} from "axios";
import { AxiosResponse } from "axios";
// Components
import { Status } from '../DataMigration';

interface MigrationButtonProps {
    item: {
        id: string;
        title: string;
        icon: string;
        apiFunction?: (options?: RawAxiosRequestConfig) => Promise<AxiosResponse>;
    };
    status: Status;
    startMigration: (domain: string) => void;
}



export function MigrationButton({
  item,
  status,
  startMigration,
}: MigrationButtonProps) {
  const display = {
    loading: "Migration in progress",
    completed: "Migration Completed",
    "not-started": "Start Migration",
    failed: "Migration Failed",
  };

  return (
    <div className="border-1 border-gray-300 flex flex-col gap-10 p-3 rounded-[4px]">
      <div className="flex items-center gap-2.5">
        <img src={item.icon} alt="icon" className="w-8 h-8" />
        <p className="text-blue-900 text-sm-medium">{item.title}</p>
      </div>
      <button
        className={`flex gap-1 text-sm-normal text-blue-500 ${status === "loading" || status === "completed" ? "cursor-text" : "cursor-pointer"}`}
        onClick={() => startMigration(item.title)}
        disabled={status === "loading" || status === "completed"}
      >
        <p className="text-sm ">{display[status]}</p>
        {status === "not-started" ? (
          <img src={ArrowTopRight} alt="arrow" />
        ) : null}
      </button>
    </div>
  );
}
