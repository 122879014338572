import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { UserProfileDomainApi } from "../../openapi";
import InputField from "../CommonInputs";
import { CustomErrorModal, CustomSuccessModal } from "../Modal/customModal";
import toast from "react-hot-toast";
import { ErrorFileData } from "../../interfaces/pages/Errors";
import { apiConfig } from "../ConfigurationApi/Configuration";
import Loader from "../Loader";
import { setUsername } from "../../features/userSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { handleFormError } from "../../utils/errorHandling";

const Profile: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState<any>(null);
  const id = localStorage.getItem('userId')  
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() =>{
    fetchProfileData()
  }, [])

  const fetchProfileData = async() => {
    setLoading(true)
    const api = new UserProfileDomainApi(apiConfig());
    const response = await  api.whoAmI()
    formik.setValues(response.data.profile || {})

    // Extract profile data and fallback to username if necessary
    const profile: any= response?.data?.profile || {};
    const nameFirst = profile.nameFirst || "";
    const nameLast = profile.nameLast || "";
    const name = nameFirst && nameLast ? `${nameFirst} ${nameLast}` : response?.data.username || "";
    dispatch(setUsername(name));
    setLoading(false)
  }


  const formik: any = useFormik({
    initialValues: { nameFirst: "", nameMiddle: "", nameLast: "", email: "" },
    onSubmit: async (values, { setErrors }) => {
      try {
        setLoading(true);

        const api = new UserProfileDomainApi(apiConfig());
        const response = await api.updateUserProfile(String(id), values) as any;
        if (response.status === 200) {
          fetchProfileData()
          toast.custom(
            (t) => <CustomSuccessModal  visible={t.visible}  message={"Record update successfully"} />,
            {
              duration: 500,
            }
          );
        }
      } catch (error: any) {
        handleFormError(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <React.Fragment>
      <div className="">
      <form className="w-full " onSubmit={formik.handleSubmit} onChange={() => setServerError(null)}>
      <div className="pb-4">
        <p className="text-lg-semibold text-blue-500">Personal Information</p>
      </div>
      
       <div className="grid grid-cols-2 grid-rows-2 gap-6">
        <div className="w-full mb-3">
          <InputField
            label="First Name"
            id="nameFirst"
            name="nameFirst"
            type="text"
            placeholder="Enter here..."
            serverError={serverError || ""}
            formik={formik}
            showIcon
            bgColor={"#fff"}
            required
            last
          />
        </div>
        <div className="w-full mb-3">
          <InputField
            label="Middle Name"
            id="nameMiddle"
            name="nameMiddle"
            type="text"
            placeholder="Enter here..."
            serverError={serverError || ""}
            formik={formik}
            showIcon
            bgColor={"#fff"}
            last
          />
        </div>
        <div className="w-full mb-3">
          <InputField
            label="Last Name"
            id="nameLast"
            name="nameLast"
            type="text"
            placeholder="Enter here..."
            serverError={serverError || ""}
            formik={formik}
            showIcon
            bgColor={"#fff"}
            required
            last
          />
        </div>

        <div className="w-full mb-3">
          <InputField
            label="Email"
            id="email"
            name="email"
            type="email"
            placeholder="Enter here..."
            serverError={serverError || ""}
            formik={formik}
            showIcon
            bgColor={"#fff"}
            required
            last
          />
        </div>
        </div>
        <div className=" mt-4 flex justify-end">
          <button
            type="submit"
            disabled={loading}
            className="  text-[#fff]  py-[12px] px-[18px] text-xs-medium rounded-radius-lg bg-blue-500"
          >
            Update
          </button>
        </div>
      </form>
    </div>
          {loading && <Loader loading={loading} />}
  </React.Fragment>
  );
};

export default Profile;
