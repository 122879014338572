import React from "react";

const KeyDates = () => {
  return (
    <div className="table-container bg-white rounded-radius-xl">
      <table className="min-w-full dark:bg-table-dark bg-table-light rounded-radius-xl">
        <caption className="text-left text-lg-semibold bg-white text-blue-500 pt-4 pb-4 px-4 rounded-t-radius-xl">
          Key Dates
        </caption>
        <tbody>
          <tr>
            <td className="px-4 pb-2 text-[#000817] dark:text-white text-sm font-medium text-left">
              Trade Inception:
            </td>
            <td className="px-4 pb-2 text-sm font-medium text-right">
              6.10.2024
            </td>
          </tr>
          <tr>
            <td className="px-4 pb-2 text-[#000817] dark:text-white text-sm font-medium text-left">
              Today:
            </td>
            <td className="px-4 pb-2 text-sm font-medium text-right">
              6.11.2024
            </td>
          </tr>
          <tr>
            <td className="px-4 pb-2 text-[#000817] dark:text-white text-sm font-medium text-left">
              Elapsed Duration:
            </td>
            <td className="px-4 pb-2 text-sm font-medium text-right">1 day</td>
          </tr>
          <tr>
            <td className="px-4 pb-2 text-[#000817] dark:text-white text-sm font-medium text-left">
              Next Earnings
            </td>
            <td className="px-4 pb-2 text-sm font-medium text-right">
              6.11.2024
            </td>
          </tr>
          <tr>
            <td className="px-4 pb-2 text-[#000817] dark:text-white text-sm font-medium text-left">
              Catalyst Expected
            </td>
            <td className="px-4 pb-2 text-sm font-medium text-right">
              $144.60
            </td>
          </tr>
          <tr>
            <td className="px-4 pb-4 text-[#000817] dark:text-white text-sm font-medium text-left">
              Timing
            </td>
            <td className="px-4 pb-4 text-sm font-medium text-right">
              6 months
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default KeyDates;
