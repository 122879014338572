import React, { useEffect, useRef, useState } from "react";
import Search from "../../assets/svg/search.svg";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  GlobalSearchFiltersEnum,
  SearchRestControllerApi,
} from "../../openapi";
import { apiConfig } from "../ConfigurationApi/Configuration";
import { ListSearch } from "./components";

const SearchDialog = () => {
  const [showHistory, setShowHistory] = useState(false);
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<any>(null);
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<any>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState<Set<GlobalSearchFiltersEnum>>(
    new Set([GlobalSearchFiltersEnum.All])
  );
  const [dateFilter, setDateFilter] = useState<string>("");
  const [domain, setDomain] = useState("ALL");
  const [currentOperator, setCurrentOperator] = useState("");
  const searchContainerRef = useRef<any>(null);

  const useDebounce = (value: any, delay: any) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  const debouncedQuery = useDebounce(query, 300);

  // Fetches search results from the new API structure
  const fetchSearchResults = async (searchQuery: string) => {
    setLoading(true);
    try {
      const pageable = { page: 0, size: 5, sort: ["asc"] };
      const api = new SearchRestControllerApi(apiConfig())
      const response = await api.globalSearch(searchQuery, filters, pageable,);
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    const trimmedQuery = debouncedQuery.trim();

    if (trimmedQuery.length === 0) {
      setSearchResults(null);
      return;
    }

    setLoading(true);
    try {
      await fetchSearchResults(trimmedQuery);
      setSearchHistory((prevHistory) => [
        trimmedQuery,
        ...prevHistory.filter((item) => item !== trimmedQuery),
      ]);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAutocomplete = (suggestion: any) => {
    setQuery(`${currentOperator}:${suggestion}`);
    setAutocompleteSuggestions([]);
  };

  const handleRemoveHistoryItem = (itemToRemove: any) => {
    setSearchHistory((prevHistory: any) =>
      prevHistory.filter((item: any) => item !== itemToRemove)
    );
    setShowHistory(true);
  };

  const handleClearQuery = () => {
    setQuery("");
    setAutocompleteSuggestions([]);
  };

  useEffect(() => {

    if (debouncedQuery) {
      handleSearch();
    } else {
      setSearchResults(null);
    }
  }, [debouncedQuery, filters, dateFilter]);

  const handleClickSearchField = () => {
    if (query.trim().length > 0) {
      handleSearch();
    } else {
      setShowHistory(true);
    }
  };

  return (
    <div className="flex w-full">
      <div
        ref={searchContainerRef}
        className={`${
          showHistory || searchResults ? "rounded-md" : "rounded-md"
        } relative invisible lg:visible bg-white dark:bg-b-secondary-dark w-full md:max-w-full`}
      >
        {/* INPUT SEARCH */}
        <div className="relative flex items-center">
          <img
            src={Search}
            alt="search"
            className="absolute top-[10px] left-3 brightness-0"
          />

          <input
            type="text"
            placeholder="Search here"
            value={query}
            onChange={(e) => {
              const value = e.target.value;
              setQuery(value);
              const match = value.match(/(ticker|company):/);
              setCurrentOperator(match ? match[1] : "");
            }}
            onClick={handleClickSearchField}
            onFocus={() => setShowHistory(true)}
            onBlur={() =>
              setTimeout(() => {
                if (
                  !searchContainerRef?.current?.contains(document.activeElement)
                ) {
                  setAutocompleteSuggestions([]);
                }
              }, 200)
            }
            onKeyDown={(e) => {
              if (e.key === "Tab" && autocompleteSuggestions.length > 0) {
                e.preventDefault();
                handleAutocomplete(autocompleteSuggestions[0]);
              }
            }}
            className="text-sm font-normal w-full bg-[#F6F8FA] pl-12 py-1 h-10 pr-12 outline-none placeholder:text-[#6B778C] dark:placeholder:text-dark text-black dark:text-dark rounded-radius-md border-1  focus:border-2 border-blue-500"
          />
          {query && (
            <FontAwesomeIcon
              icon={faCircleXmark}
              color="blue"
              className="absolute top-3 right-3 cursor-pointer brightness-0 "
              onClick={handleClearQuery}
            />
          )}
        </div>
        {/* SHOW DATA */}
        <ListSearch
          searchResults={searchResults}
          showHistory={showHistory}
          loading={loading}
          query={query}
          searchHistory={searchHistory}
          setQuery={setQuery}
          handleSearch={handleSearch}
          handleRemoveHistoryItem={handleRemoveHistoryItem}
          autocompleteSuggestions={autocompleteSuggestions}
          handleAutocomplete={handleAutocomplete}
          debouncedQuery={debouncedQuery}
          setSearchResults={setSearchResults}
          setFilters={setFilters}
          filters={filters}
          setDateFilter={setDateFilter}
          dateFilter={dateFilter}
          setShowHistory={setShowHistory}
        />
      </div>
    </div>
  );
};

export default SearchDialog;
