import { MnaDomainApi } from "../../../openapi";
import { handleFormError } from "../../../utils/errorHandling";
import { apiConfig } from "../../ConfigurationApi/Configuration";


interface BrokerClosingDate {
  dateEvent: string | null;
  comment: string;
  mnaId: string;
  isNew?: boolean;
}

interface PatchValue {
  add: BrokerClosingDate[];
}

interface JsonPatch {
  op: string;
  path: string;
  value: PatchValue;
}


export const addBrokerClosingDateLocally = (
  fieldName: string,
  id: string,
  setData: React.Dispatch<React.SetStateAction<Record<string, any[]>>>,
  tempId?: string,
  afterIndex?: number,
isNew?:boolean
) => {
  if(!isNew){
    isNew = true
  }
  setData((prevData) => {
    const currentDates = [...prevData[fieldName]];
    if (typeof afterIndex === "number") {
      currentDates.splice(afterIndex + 1, 0, {
        dateEvent: null,
        comment: "",
        mnaId: id,
        isNew: isNew,
        id: tempId,
      });
    } else {
      currentDates.push({
        dateEvent: null,
        comment: "",
        mnaId: id,
        isNew: isNew,
        id: tempId,
      });
    }

    return {
      ...prevData,
      [fieldName]: currentDates,
    };
  });
};


export const editClosingDateLocally = (
  fieldName: string,
  index: number,
  updatedDate: string,
  updatedComment: string,
  setData: React.Dispatch<React.SetStateAction<any>>
) => {
  setData((prevData:any) => {
    const updatedField = prevData[fieldName].map((item: any, i: number) => 
      i === index ? { ...item, dateEvent: updatedDate, comment: updatedComment } : item
    );
    
    return {
      ...prevData,
      [fieldName]: updatedField
    };
  });
};


export const sendUpdatedDataToServer = async (
  fieldName: string,
  data: Record<string, BrokerClosingDate[]>,
  id: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setData: React.Dispatch<React.SetStateAction<Record<string, BrokerClosingDate[]>>>,
  dateEventValue: string,
  comment?:string
) => {
  try {
    setLoading(true);
    const api = new MnaDomainApi(apiConfig());

    const newEntries = data[fieldName].filter((item) => item.isNew);
    
    if (newEntries.length === 0) return; 

    const jsonPatch: JsonPatch[] = [
      {
        op: "replace",
        path: `/${fieldName}`,
        value: {
          add: newEntries.map((entry) => ({
            dateEventTypeId: 3,
            dateEvent: dateEventValue,
            comment: comment || "",
            mnaId: id,
          })),
        },
      },
    ];

    await api.partiallyUpdateMna(id, jsonPatch);

    const response = await api.getMnaById(id);
    const fullServerData:any = response.data;

    if (!fullServerData || !fullServerData[fieldName]) {
      throw new Error(`Server didn't return ${fieldName} data`);
    }

    setData((prev) => ({
      ...prev,
      [fieldName]: fullServerData[fieldName]
        .map((item: any) => ({
          ...item,
          isNew: false,
        }))
        .sort((a:any, b:any) => new Date(a.dateEvent).getTime() - new Date(b.dateEvent).getTime()), 
    }));

    return fullServerData;

  } catch (error) {
    handleFormError(error);
    return data; 
  } finally {
    setLoading(false);
  }
};
