import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchMnaData } from "../pages/MnaDetails/services/mnaService";

interface MnaDetailsState {
  mnaDetails: any;
  loadingMnaDetails: boolean; 
}

const initialState: MnaDetailsState = {
  mnaDetails: {},
  loadingMnaDetails: false, 
};

export const fetchMnaDetails = createAsyncThunk(
  "mnaDetails/fetchMnaDetails",
  async (id: string, { rejectWithValue }) => {
    try {
      const data = await fetchMnaData(id);

      if (!data || typeof data !== "object") {
        throw new Error("Error while fetching data");
      }
      return data;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const mnaDetailsSlice = createSlice({
  name: "mnaDetails",
  initialState,
  reducers: {
    setMnaDetails(state, action: PayloadAction<any>) {
      state.mnaDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMnaDetails.pending, (state) => {
        state.loadingMnaDetails = true;
      })
      .addCase(
        fetchMnaDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loadingMnaDetails = false; 
          state.mnaDetails = action.payload;
        }
      )
      .addCase(fetchMnaDetails.rejected, (state, action) => {
        state.loadingMnaDetails = false; 
        console.error("Failed to get data", action.payload);
      });
  },
});

export const { setMnaDetails: setMnaDetailsAction } = mnaDetailsSlice.actions;
export default mnaDetailsSlice.reducer;
