import React, { forwardRef, useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { OptionItem } from "../../interfaces/pages/variedInterfaces";
import { handleFormError } from "../../utils/errorHandling";
import { addBrokerClosingDateLocally } from "./EditableFieldsUtilities/editableFieldsUtils";

// Icons
import ArrowRight from "../../assets/svg/arrow-right.svg";
import Arrowleft from "../../assets/svg/arrow-left.svg";
import moment from "moment";

// Helper function to format date to ISO string
const formatDateToISOString = (date: Date, time: any): string => {
  if (time) {
    return date.toISOString();
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

interface InputFieldProps {
  label?: any;
  id: any;
  name: any;
  type: "text" | "date" | "textarea" | "select" | "email";
  placeholder: any;
  serverError?: any;
  formik?: any;
  showIcon?: any;
  rows?: any;
  required?: any;
  last?: any;
  optionList?: any;
  labelKey?: any;
  valueKey?: any;
  bgColor?: any;
  time?: any;
  onChange?: ((value: any) => void) | undefined;
  isDynamic?: any;
  value?: any;
  showAdditionalComment?: any;
  onBlur?: any;
  className?: any;
  maxlength?: any;
  onKeyDownCapture?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  autoSubmit?: boolean;
  cancel?: any;
  setSelectedValue?: any;
  dateType?: string;
}

const InputFieldMna = forwardRef<any, InputFieldProps>(
  (
    {
      id,
      name,
      type,
      placeholder,
      serverError,
      formik,
      showIcon,
      rows,
      required,
      last,
      optionList,
      labelKey,
      valueKey,
      bgColor,
      time,
      isDynamic,
      value,
      onChange,
      maxlength,
      onKeyDownCapture,
      autoSubmit,
      cancel,
      setSelectedValue,
      dateType,
    },
    ref
  ) => {
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      let newValue = event.target.value;
      if (typeof newValue === "boolean") {
        setSelectedValue(event.target.value);
        newValue = String(newValue);
      }
      formik.setFieldValue(name, newValue);
      if (autoSubmit) {
        formik.handleSubmit();
      }
      if (onChange) {
        onChange(newValue);
      }
    };

    let formattedDate: string = "";
    let selectedFormattedDate: Date | null = null;

    if (type === "date") {
      const dateString = formik?.values[id] || value || "";
      if (dateString) {
        const mDate = moment(dateString, ["MM/DD/YYYY", "YYYY-MM-DD"], true);
        if (mDate.isValid()) {
          selectedFormattedDate = mDate.toDate();
        }
      }
    }

    const [isCalendarFieldOpen, setCalendarFieldOpen] = useState(false);
    useEffect(() => {
      if (isCalendarFieldOpen) {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === "Enter") {
            setCalendarFieldOpen(false);
          }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
          document.removeEventListener("keydown", handleKeyDown);
        };
      }
    }, [isCalendarFieldOpen, formik]);

    return (
      <React.Fragment>
        {type === "text" ? (
          <div className="relative" onKeyDownCapture={onKeyDownCapture}>
            <input
              id={id}
              name={name}
              type="text"
              maxLength={maxlength}
              placeholder={placeholder}
              value={
                isDynamic
                  ? value || ""
                  : formik?.values
                  ? formik?.values[name]
                  : ""
              }
              onChange={onChange ? onChange : formik && formik.handleChange}
              className={`py-[18px] px-[30px] border rounded-full text-base text-[#7B7B7B] font-light w-full outline-none ${
                serverError && serverError[id]
                  ? "border-danger :border-danger"
                  : "border-[#C3C1DF] dark:border-white"
              } bg-[${
                bgColor ? bgColor : "#FAFAFE"
              }] dark:bg-primary-dark dark:text-white ${
                showIcon && "pr-[50px]"
              }`}
              onBlur={() => {
                setTimeout(() => {}, 0);
              }}
            />
            {showIcon && <span className="absolute right-7 top-5"></span>}
          </div>
        ) : type === "date" ? (
          <ReactDatePicker
            autoComplete="off"
            autoFocus
            id={id}
            name={name}
            selected={selectedFormattedDate}
            placeholderText={placeholder}
            className={`dark:text-white dark:placeholder:text-white dark:bg-primary-dark border rounded-radius-md a text-left text-xs-normal mt-2 text-blue-900 max-w-[120px] min-w-[70px] self-start font-light pl-2 py-[2px] w-full bg-[${
              bgColor ? bgColor : "#FAFAFE"
            }] outline-none ${
              serverError && serverError[id]
                ? "border-danger dark:border-danger"
                : "border-blue-500 dark:border-white"
            }`}
            onChange={(date) => {
              try {
                let formattedDate = "";
                if (date) {
                  if (time) {
                    formattedDate = date.toISOString();
                  } else {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, "0");
                    const day = String(date.getDate()).padStart(2, "0");
                    formattedDate = `${year}-${month}-${day}`;
                  }
                }
                formik.setFieldValue(id, formattedDate);
                if (onChange) onChange(formattedDate);
              } catch (error) {
                handleFormError(error);
              }
            }}
            // onBlur={cancel}
            dateFormat={time ? "yyyy/MM/dd h:mm aa" : "MM/dd/yyyy"}
            showMonthDropdown
            showPopperArrow={false}
            shouldCloseOnSelect={false}
            open={isCalendarFieldOpen}
            onClickOutside={() => {
              setCalendarFieldOpen(false);
              if (dateType !== "noconfirm") {
                formik.setFieldValue(id, formik.initialValues.inputValue);
                cancel && cancel();
              }
            }}
            onFocus={() => setCalendarFieldOpen(true)}
            showYearDropdown
            dropdownMode="select"
            showTimeSelect={time}
            timeFormat="HH:mm"
            timeIntervals={5}
            timeCaption="time"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setCalendarFieldOpen(false);
                if (
                  formik.values[id] !== formik.initialValues[id] &&
                  dateType !== "noconfirm"
                ) {
                  formik.handleSubmit();
                } else {
                  if (dateType !== "noconfirm") {
                    cancel && cancel();
                  }
                }
              }
            }}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: 10,
                  alignItems: "center",
                  color: "#16234A",
                  fontWeight: 700,
                }}
              >
                <button
                  type="button"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <img src={Arrowleft} />
                </button>
                <span>
                  {date.toLocaleString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}
                </span>
                <button
                  type="button"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  <img src={ArrowRight} />
                </button>
              </div>
            )}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "16px",
                padding: "1px",
                textAlign: "center",
              }}
            >
              <button
                className="w-1/2 bg-blue-primary rounded-lg py-2 text-white"
                onClick={() => {
                  setCalendarFieldOpen(false);
                  if (dateType !== "noconfirm") {
                    formik.handleSubmit();
                  }
                }}
              >
                Ok
              </button>
              <button
                className="w-1/2 bg-white rounded-lg border-1 py-2"
                onClick={() => {
                  setCalendarFieldOpen(false);
                  if (dateType !== "noconfirm") {
                    cancel && cancel();
                  }
                }}
              >
                Cancel
              </button>
            </div>
          </ReactDatePicker>
        ) : type === "textarea" ? (
          <div className="relative">
            <textarea
              id={id}
              name={name}
              value={
                isDynamic
                  ? value || ""
                  : formik?.values
                  ? formik?.values[name]
                  : ""
              }
              rows={rows}
              placeholder={placeholder}
              onChange={formik?.handleChange}
              onBlur={cancel}
              className={`py-[18px] px-[30px] border dark:text-white dark:placeholder:text-white dark:bg-primary-dark rounded-lg text-base text-[#7B7B7B] font-light w-full bg-[${
                bgColor ? bgColor : "#FAFAFE"
              }] outline-none ${
                serverError && serverError[id]
                  ? "border-danger dark:border-danger"
                  : "border-[#C3C1DF] dark:border-white"
              } ${showIcon && "pr-[50px]"}`}
            />
            {showIcon && <span className="absolute right-7 top-5"></span>}
          </div>
        ) : type === "select" ? (
          <div className="relative">
            <select
              id={id}
              name={name}
              value={
                isDynamic
                  ? value || ""
                  : formik?.values
                  ? formik?.values[name]
                  : ""
              }
              onChange={handleChange}
              onBlur={cancel}
              autoFocus={true}
              className={`appearance-none px-[10px] border border-blue-500 cursor-text rounded-md text-sm-normal text-[#7B7B7B] h-full font-light w-full bg-[${
                bgColor ? bgColor : "#FAFAFE"
              }] outline-none ${
                serverError && serverError[id]
                  ? "border-danger dark:border-danger"
                  : "border-[#C3C1DF] dark:border-white"
              } dark:text-white dark:placeholder:text-white dark:bg-primary-dark `}
            >
              <option value="">{placeholder}</option>
              {optionList.map((item: OptionItem, index: number) => (
                <option key={index} value={valueKey && item[valueKey]}>
                  {labelKey && item[labelKey]}
                </option>
              ))}
            </select>
            <span className="absolute top-1/2 right-[5px] -translate-y-1/2"></span>
          </div>
        ) : type === "email" ? (
          <div className="relative">
            <input
              id={id}
              name={name}
              type="email"
              placeholder={placeholder}
              value={
                isDynamic
                  ? value || ""
                  : formik?.values
                  ? formik?.values[name]
                  : ""
              }
              onChange={onChange ? onChange : formik && formik.handleChange}
              onBlur={() => {
                setTimeout(() => {}, 0);
              }}
              className={`py-[18px] px-[30px] border rounded-full text-base text-[#7B7B7B] font-light w-full outline-none ${
                serverError && serverError[id]
                  ? "border-danger dark:border-danger"
                  : "border-[#C3C1DF] dark:border-white"
              } bg-[${
                bgColor ? bgColor : "#FAFAFE"
              }] dark:bg-primary-dark dark:text-white ${
                showIcon && "pr-[50px]"
              }`}
            />
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
);

export default InputFieldMna;
