// React
import React, { useRef, useState } from 'react';
// Third party
import ReactDatePicker from 'react-datepicker';
// Images
import CalendarIcon from '../../../assets/svg/calendar.svg';
import Arrowleft from "../../../assets/svg/arrow-left.svg";
import ArrowRight from "../../../assets/svg/arrow-right.svg";

interface DatePickerMiniFormProps {
  selectedDate: Date | null;
  handleDateChange: (date: Date | null) => void;
  formik: any;
  datePickerRef: React.RefObject<ReactDatePicker>;
}

export default function DatePickerMna({
  selectedDate,
  handleDateChange,
  formik,
  datePickerRef,
}: DatePickerMiniFormProps) {
  const [isCalendarFieldOpen, setCalendarFieldOpen] = useState(false);

  return (
    <>
      <ReactDatePicker
        autoComplete="off"
        ref={datePickerRef}
        selected={selectedDate}
        onChange={handleDateChange}
        onBlur={formik.handleBlur}
        name="dateAnnounced"
        id='dateAnnounced'
        className={`dark:text-white  dark:bg-primary-dark border-1 border-blue-primary rounded-[8px] text-left px-4 text-base text-[#7B7B7B] h-12 font-light  outline-none min-w-full ${
          formik.errors.dateAnnounced && formik.touched.dateAnnounced
            ? 'border border-[#ff6464]'
            : ''
        }`}
        dateFormat="MM/dd/yyyy"
        showMonthDropdown
        showYearDropdown
        showPopperArrow={false}
        shouldCloseOnSelect={false}
        dropdownMode="select"
        open={isCalendarFieldOpen}
        onClickOutside={() => setCalendarFieldOpen(false)}
        onFocus={() => setCalendarFieldOpen(true)}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: 10,
              alignItems: "center",
              color: "#16234A",
              fontWeight: 700,
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <img src={Arrowleft} />
            </button>
            <span>
              {date.toLocaleString("en-US", { month: "long", year: "numeric" })}
            </span>
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <img src={ArrowRight} />
            </button>
          </div>
        )}

      >
           <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
            padding: "1px",
            textAlign: "center",
          }}
        >
          <button
            className="w-1/2 bg-blue-primary rounded-lg py-2 text-white"
            onClick={() => setCalendarFieldOpen(false)}
          >
            Ok
          </button>
          <button
            className="w-1/2 bg-white rounded-lg border-1 py-2"
            onClick={() => setCalendarFieldOpen(false)}
          >
            Cancel
          </button>
        </div>
      </ReactDatePicker>
      <img
        src={CalendarIcon}
        alt="Calendar Icon"
        className="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none w-5 h-5"
        onClick={() => datePickerRef.current?.setFocus()}
      />
      </>
  );
}
