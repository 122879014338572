import React from "react";

interface SaveButtonProps {
  type: "button" | "submit" | "reset"; 
}

const SaveButtonMna: React.FC<SaveButtonProps> = ({ type }) => {
  return (
    <button
      type={type}
      className="bg-blue-500 text-[#fff] rounded-radius-lg  py-[12px] px-[18px] min-w-[90px] flex items-center justify-center  text-sm-medium "
    >
      Save
    </button>
  );
};

export default SaveButtonMna;
