// React
import React, { useEffect, useState } from "react";
// Types
import { ItemMetaData } from "../../../interfaces/pages/variedInterfaces";

// Images
import AddContactIcon from "../../../assets/svg/addContactIcon.svg";
import { useFormik } from "formik";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import checkmark from "../../../assets/images/checkmark.png";
import DeleteIcon from "../../../assets/svg/delete.svg";

import close from "../../../assets/svg/close.svg";
// API
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import {
  LinkAssociationDomainApi,
  LinkCategoryDomainApi,
  LinkDomainApi,
} from "../../../openapi";

import { fetchMnaData } from "../services/mnaService";
import { useParams } from "react-router-dom";
import DeleteModalMNADates from "../../../components/Modal/DeleteModalMNADates";
import { version } from "os";
import toast from "react-hot-toast";
import { CustomErrorModal } from "../../../components/Modal/customModal";

export default function MnaLinks() {
  const [isLinktAdd, setIsLinkAdd] = useState<boolean>(false);
  const [linkCategories, setLinkCategories] = useState<any>([]);
  const [data, setData] = useState<any>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<string>("");
  const [linkIdToDelete, setIdLinkToDelete] = useState<string>("");
  const [dropdownOpen, setDropdownOpen] = useState<any>();

  const deletelink = async () => {
    try {
      const api = new LinkDomainApi(apiConfig());
      await api.deleteLink(linkIdToDelete);
      setData((prevData: any) => ({
        ...prevData,
        links: prevData.links.filter((link: any) => link.id !== linkIdToDelete),
      }));
    } catch (error) {
      console.error("Error deleting link:", error);
    } finally {
      closeDeleteModal();
    }
  };

  const editLink = async (
    id: any,
    version: any,
    linkCategoryId: any,
    link: any
  ) => {
    if (isNaN(version)) {
      version = 1;
    }

    try {
      const api = new LinkDomainApi(apiConfig());
      await api.updateLink(String(id), {
        version,
        linkCategoryId: linkCategoryId,
        link: String(link),
      });

      const updatedCategory = linkCategories.find(
        (cat: any) => cat.id === linkCategoryId
      )?.name;

      setData((prevData: any) => ({
        ...prevData,
        links: prevData.links.map((item: any) =>
          item.id === id
            ? {
                ...item,
                version: item.version + 1,
                linkCategoryId,
                link,
                category: updatedCategory,
              }
            : item
        ),
      }));
    } catch (error) {
      console.error("Error updating link:", error);
      formik.resetForm();
      setIsLinkAdd(false);
      setIsEditing("");
      toast.custom(
        (t) => <CustomErrorModal visible={t.visible} error={error} />,
        {
          duration: 3500,
        }
      );
    }
  };

  async function getMnaData() {
    try {
      const mnaData = await fetchMnaData(id!);
      setData(mnaData);
    } catch (error) {}
  }

  useEffect(() => {
    fetchLinksCategories();

    getMnaData();
  }, []);

  const closeDeleteModal = () => {
    setIdLinkToDelete("");
    setIsDeleteModalOpen(false);
  };

  const fetchLinksCategories = async () => {
    try {
      const api = new LinkCategoryDomainApi(apiConfig());
      const response = await api.getLinkCategories();
      setLinkCategories(response.data.content);
    } catch (error) {
      console.log(error);
      setIsLinkAdd(false);
    }
  };

  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      category: 0,
      url: "",
    },
    onSubmit: async (values: any) => {
      try {
        const api = new LinkDomainApi(apiConfig());
        const response = await api.createLink({
          version: 0,
          linkCategoryId: values.category,
          link: values.url,
        });

        const newLink = {
          id: response.data,
          linkCategoryId: values.category,
          category: linkCategories.find(
            (cat: any) => cat.id === values.category
          )?.name,
          link: values.url,
          version: 0,
        };

        setData((prevData: any) => ({
          ...prevData,
          links: [...prevData.links, newLink],
        }));

        formik.resetForm();

        const api2 = new LinkAssociationDomainApi(apiConfig());
        api2.createLinkAssociation({
          typeId: 1,
          entityId: id!,
          linkId: response.data,
        });

        setIsLinkAdd(false);
      } catch (error) {
        console.log(error);
        setIsEditing("");
        formik.resetForm();
        setIsLinkAdd(false);
        toast.custom(
          (t) => <CustomErrorModal visible={t.visible} error={error} />,
          {
            duration: 500,
          }
        );
      }
    },
  });

  const handleEdit = (item: ItemMetaData) => {
    setIsEditing(item.id);
    formik.setFieldValue("url", item.link);
    formik.setFieldValue("category", item.linkCategoryId);
  };

  return (
    <div
      className={`table-container pb-4 bg-table-light dark:bg-table-dark rounded-radius-lg relative ${
        data?.links?.length > 0 ? "min-h-[200px]" : ""
      }`}
    >
      <table className="w-full rounded-bl-lg rounded-br-lg  bg-table-light dark:bg-table-dark relative">
        <caption className="text-left text-blue-500 text-lg-medium pt-4 pb-2 px-4 ">
          Links{" "}
          <div className="text-right flex justify-end absolute top-[16px] right-[16px] ">
            <img
              src={AddContactIcon}
              alt="Add Link"
              className="cursor-pointer text-end"
              onClick={() => {
                setIsLinkAdd(true);
                setIsEditing("");
                formik.resetForm();
              }}
            />
          </div>
        </caption>
        <thead>
          <tr>
            <th scope="col" className="w-1/3"></th>
            <th scope="col" className="w-2/3"></th>
          </tr>
        </thead>
        <tbody className="">
          <tr>
            <td className="px-4  text-xs-medium text-blue-900 dark:text-white  text-left ">
              Category
            </td>
            <td className="px-4  text-xs-medium dark:text-white  text-blue-900 truncate max-w-[calc(100vw-72rem)]">
              URL
            </td>
          </tr>

          {data?.links?.map((item: ItemMetaData, index: number) => (
            <>
              <tr
                key={`links-${index}`}
                onMouseOver={() => setDropdownOpen(item.id)}
                onMouseLeave={() => setDropdownOpen("")}
              >
                <td>
                  <div
                    onDoubleClick={() => {
                      formik.setFieldValue("url", item.link);
                      formik.setFieldValue("category", item.linkCategoryId);
                      setIsLinkAdd(false);
                      setIsEditing(item.id);
                    }}
                    className="px-4 text-xs-normal text-gray-500 dark:text-white text-sm font-medium text-left"
                  >
                    {isEditing == item.id ? (
                      <div className="mt-[6px]">
                        <CustomSelect
                          name="category"
                          value={formik.values.category || ""}
                          onChange={formik.handleChange}
                          options={linkCategories}
                        />
                      </div>
                    ) : (
                      <div className="-ml-2 px-2 rounded-[4px] py-2 mt-[6px] hover:bg-gray-100">
                        <p className="text-blue-900 text-xs-normal">
                          {item?.category}
                        </p>
                      </div>
                    )}
                  </div>
                </td>
                <td className="text-xs-normal text-gray-500 dark:text-white text-sm font-medium truncate max-w-[calc(100vw-72rem)] ">
                  <form onSubmit={formik.handleSubmit}>
                    <div
                      className="mt-[6px] "
                      onDoubleClick={() => {
                        formik.setFieldValue("url", item.link);
                        setIsLinkAdd(false);
                        formik.setFieldValue("category", item.linkCategoryId);
                        setIsEditing(item.id);
                      }}
                    >
                      {isEditing == item.id ? (
                        <div className="pl-4">
                          <input
                            type="text"
                            name="url"
                            value={formik.values.url || ""}
                            onChange={formik.handleChange}
                            className="w-full  mt-[6px] px-2 py-1 border border-[#4A8DF1] rounded-radius-sm text-blue-900 focus:outline-none text-xs-normal text-left truncate min-h-[27px]"
                          />
                        </div>
                      ) : (
                        <div className="hover:bg-gray-100 py-2 px-4 rounded-[4px] min-h-[34px]">
                          <a
                            className="block truncate text-blue-900 text-xs-normal hover:text-blue-500"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={item.link}
                          >
                            {item?.link}
                          </a>
                        </div>
                      )}
                    </div>
                    {isEditing == item.id && (
                      <div className="absolute right-[35px] mt-[3px] ">
                        <button
                          type="submit"
                          onClick={() => {
                            editLink(
                              item.id,
                              Number(item.version) + 1,
                              formik.values.category || item.linkCategoryId,
                              formik.values.url
                            );
                            setIsEditing("");
                          }}
                          className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-200 border-1 border-primary-light shadow-shadow-md mr-[2px]"
                        >
                          <img
                            className="w-3 h-3"
                            src={checkmark}
                            alt="checkmark"
                          />
                        </button>

                        <button
                          onClick={() => {
                            formik.setFieldValue("url", item.link);
                            setIsEditing("");
                          }}
                          type="button"
                          className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-50 border-1 border-primary-light shadow-shadow-md"
                        >
                          <img src={close} className="w-3 h-3" alt="close" />
                        </button>
                      </div>
                    )}
                  </form>
                </td>
                <td>
                  <div
                    className={`ml-[8px] w-[20px] mr-2 mt-2 ${
                      dropdownOpen === item.id ? "block" : "invisible"
                    } `}
                  >
                    <div
                      className=" cursor-pointer flex items-center justify-center "
                      id="edit1"
                    >
                      <img
                        src={DeleteIcon}
                        alt="delete"
                        className="w-[20px] h-[20px]"
                        onClick={() => {
                          setIsEditing("");
                          setIsLinkAdd(false);
                          setIdLinkToDelete(item.id);
                          setIsDeleteModalOpen((prev) => !prev);
                        }}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </>
          ))}
          {isLinktAdd && (
            <tr>
              <td className="px-4 pt-2 text-xs-normal text-gray-500 dark:text-white text-sm font-medium text-left ">
                <CustomSelect
                  name="category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  options={linkCategories}
                />
              </td>
              <td className="pl-4 pt-2 text-xs-normal text-gray-500 dark:text-white text-sm font-medium truncate max-w-[calc(100vw-72rem)]">
                <form onSubmit={formik.handleSubmit}>
                  <input
                    type="text"
                    name="url"
                    value={formik.values.url}
                    onChange={formik.handleChange}
                    className="w-full mt-[6px] px-2 py-1 border border-[#4A8DF1] rounded-[2px] text-blue-900 focus:outline-none text-xs-normal text-left truncate min-h-[27px]"
                  />
                  <div className="absolute right-[35px] mt-[3px] ">
                    <button
                      type="submit"
                      className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-200 border-1 border-primary-light shadow-shadow-md mr-[2px]"
                    >
                      <img
                        className="w-3 h-3"
                        src={checkmark}
                        alt="checkmark"
                      />
                    </button>

                    <button
                      onClick={() => {
                        setIsLinkAdd((prev) => !prev);
                        formik.resetForm();
                      }}
                      type="button"
                      className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-50 border-1 border-primary-light shadow-shadow-md"
                    >
                      <img src={close} className="w-3 h-3" alt="close" />
                    </button>
                  </div>
                </form>
              </td>
              <td>
                <div className="ml-[8px] w-[20px] mr-2 mt-2"></div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {isDeleteModalOpen && (
        <DeleteModalMNADates
          title="Are you sure you want to delete this date?"
          onClose={closeDeleteModal}
          onConfirm={deletelink}
        />
      )}
    </div>
  );
}
