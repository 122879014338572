import React from "react";

const TradingData = () => {
  return (
    <div className="table-container bg-white rounded-radius-xl">
      <table className="min-w-full dark:bg-table-dark '">
        <caption className="text-left text-lg-semibold text-blue-500 pt-[16px] pb-2 px-[16px]">
          Trading Data
        </caption>
        <tbody>
          <tr className="w-full">
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-left">
              Current Price:
            </td>
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-right">
              $144.60
            </td>
          </tr>
          <tr className="w-full">
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-left">
              Value Traded (3 mon):
            </td>
            <td className="px-4 text-sm-normal text-blue-900 text-right">77.57</td>
          </tr>
          <tr className="w-full">
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-left">
              Short interest (m):
            </td>
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-right">5.59</td>
          </tr>
          <tr className="w-full">
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-left">
              SI % of Float:
            </td>
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-right">13.1%</td>
          </tr>
          <tr className="w-full">
            <td className="px-4 pb-2 text-sm-normal text-blue-900text-left">
              Implied Volatility:
            </td>
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-right">38.0%</td>
          </tr>
          <tr className="w-full">
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-left">
              52-Weeks High:
            </td>
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-right">
              188.65%
            </td>
          </tr>
          <tr className="w-full">
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-left">
              52-Weeks Low:
            </td>
            <td className="px-4 pb-2 text-sm-normal text-blue-900 text-right">
              109.48%
            </td>
          </tr>
          <tr className="w-full">
            <td className="px-4 pb-4 text-sm-normal text-blue-900 text-left">
              YTD Return:
            </td>
            <td className="px-4 pb-4 text-sm-normal text-blue-900 text-right">6.1%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TradingData;
