import React from "react";
import moment from "moment/moment";
import EditableFieldFundamental from "../../../../components/EditableFields/EditableFieldFundamental";

interface CompanyInformationProps {
  data: any;
  sectorList: any[];
  fetchFundamentalData: () => Promise<void>;
}

const CompanyInformation: React.FC<CompanyInformationProps> = ({
  data,
  sectorList,
  fetchFundamentalData,
}) => {
  return (
    <div>
      <div className=" min-w-full inline-block align-middle ">
        <div className=" rounded-radius-xl bg-white pb-2">
          <table className="min-w-full dark:bg-primary-dark bg-white ">
            <caption className="text-left text-lg-semibold text-blue-500   pt-4 px-4">
              Company Information
            </caption>
            <tbody className="divide-[#DCDBEE] dark:bg-table-dark dark:text-white">
              <tr>
                <td className="px-4 py-2  text-sm-normal text-blue-900 w-1/2">
                  Ticker:
                </td>
                <td className="px-4  text-[#000817] dark:text-white text-base font-medium text-left w-1/2">
                  <EditableFieldFundamental
                    data={data?.ticker}
                    fieldtype="text"
                    fieldName="ticker"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2  text-sm-normal text-blue-900 w-1/2">
                  Sector:
                </td>
                <td className="px-4 text-[#000817] dark:text-white text-base font-medium text-left w-1/2">
                  <EditableFieldFundamental
                    data={data?.sectorId}
                    fieldtype="select"
                    fieldName="sectorId"
                    sectorList={sectorList}
                    fetchFundamentalData={fetchFundamentalData}
              
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2  text-sm-normal text-blue-900 w-1/2">
                  Incorporation:
                </td>
                <td className="px-4 text-[#000817] dark:text-white text-base font-medium text-left w-1/2">
                  <EditableFieldFundamental
                    data={data?.incorp}
                    fieldtype="text"
                    fieldName="incorp"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2  text-sm-normal text-blue-900 w-1/2">
                  Headquarters:
                </td>
                <td className="px-4 text-[#000817] dark:text-white   text-base font-medium text-left w-[37.5%] h-full w-1/2">
                  <EditableFieldFundamental
                    data={data?.hq}
                    fieldtype="text"
                    fieldName="hq"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2  text-sm-normal text-blue-900 w-1/2">
                  Geo Exposure:
                </td>
                <td className="px-4 text-[#000817] dark:text-white text-base font-medium text-left w-1/2">
                  <EditableFieldFundamental
                    data={data?.geoExposure}
                    fieldtype="text"
                    fieldName="geoExposure"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2  text-sm-normal text-blue-900 w-1/2">
                  Date IPO:
                </td>
                <td
                  className={`px-[${
                    data?.dateIpo ? "24px" : "16px"
                  }] text-[#000817] dark:text-white text-base font-medium text-left w-[37.5%] h-full w-1/2`}
                >
                  <EditableFieldFundamental
                     data={
                      data?.dateIpo
                        ? moment.utc(data?.dateIpo).format("MM/DD/YYYY")
                        : ""
                    }
                    fieldtype="date"
                    fieldName="dateIpo"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2  text-sm-normal text-blue-900 h-full w-1/2">
                  Address:
                </td>
                <td className="px-4 text-[#000817] dark:text-white text-base font-medium text-left w-[37.5%] h-full w-1/2">
                  <EditableFieldFundamental
                    data={data?.address}
                    fieldtype="text"
                    fieldName="address"
                    fetchFundamentalData={fetchFundamentalData}
                  />
                </td>
              </tr>
              {/* <tr className="mt-5">
                <td className="px-4 pt-2 pb-4 text-sm-normal text-blue-900">
                  {data?.description}
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation;
