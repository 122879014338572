import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/storeHooks";
import { apiConfig } from "../ConfigurationApi/Configuration";
import { MnaDomainApi } from "../../openapi";
import Loader from "../Loader";
import InputFieldMna from "./InputFieldMna";
import {
  editClosingDateLocally,
  sendUpdatedDataToServer,
} from "./EditableFieldsUtilities/editableFieldsUtils";
import { fetchMnaDetails } from "../../features/mnaDetailsSlice";

interface EditableDateFieldProps {
  data?: any;
  fieldName: string;
  dateIndex?: number;
  fullData?: any;
  setLocalData: React.Dispatch<React.SetStateAction<any>>;
  dateItemValue: string;
  fetchMnaData: () => Promise<void>;
  active: boolean;
  setActiveRow: (id: string | null) => void;
  rowId: string;
  ref: any;
  commentValue: string;
  setCommentValue:any;
}

const formatDate = (dateString: string) => {
  const [year, month, day] = dateString.split("-");
  return `${month}/${day}/${year}`;
};

const EditableDateField: React.FC<EditableDateFieldProps> = forwardRef(
  (
    {
      data,
      fieldName,
      dateIndex,
      fullData,
      setLocalData,
      dateItemValue,
      fetchMnaData,
      active,
      setActiveRow,
      rowId,
      commentValue,
      setCommentValue
    },
    ref
  ) => {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const safeId = id!;
    const dispatch = useAppDispatch();

    const [initialValue, setInitialValue] = useState(data || "");
    const currentComment = Array.isArray(fullData?.[fieldName]) 
    ? fullData[fieldName][dateIndex!]?.comment 
    : commentValue;

    const formik = useFormik({
        initialValues: {
          inputValue: dateItemValue ?? "",
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
          try {
            if (
              Array.isArray(fullData?.[fieldName]) &&
              fullData[fieldName][dateIndex!]?.isNew
            ) {
              editClosingDateLocally(
                fieldName,
                dateIndex!,
                values.inputValue,
                fullData[fieldName][dateIndex!].comment,
                setLocalData,
              );
  
              await sendUpdatedDataToServer(
                fieldName,
                fullData,
                safeId,
                setLoading,
                setLocalData,
                values.inputValue,
                currentComment
              );
              dispatch(fetchMnaDetails(id!));
              return
            } else if (Array.isArray(fullData?.[fieldName])) {
              editClosingDateLocally(
                fieldName,
                dateIndex!,
                values.inputValue,
                currentComment,
                setLocalData
              );
            }
            
            setInitialValue(formatDate(values.inputValue));
  
            const api = new MnaDomainApi(apiConfig());
            const jsonPatch = [
              {
                op: "replace",
                path: `/${fieldName}`,
                value: {
                  edit: {
                    [String(fullData?.[fieldName]?.[dateIndex!]?.id)]: {
                      mnaId: safeId,
                      dateEvent: values.inputValue,
                      comment: currentComment, 
                    },
                  },
                },
              },
            ];
            
            await api.partiallyUpdateMna(safeId, jsonPatch);
            formik.resetForm();
            await fetchMnaData();
            dispatch(fetchMnaDetails(safeId));
          } catch (error) {
            console.error("Error updating date:", error);
          } finally {
            setActiveRow(null);
          }
        },
      });

    useEffect(() => {
      const sanitizedData = data === null ? "" : data;
      setInitialValue(sanitizedData);
      formik.setFieldValue("inputValue", sanitizedData);
    }, [data]);

    useEffect(() => {
      formik.setFieldValue("inputValue", dateItemValue);
    }, [dateItemValue]);

    const handleEdit = () => setActiveRow(rowId);

    const handleCancel = () => {
      setActiveRow(null);
      formik.setFieldValue("inputValue", initialValue);
    };

    useImperativeHandle(ref, () => ({
      submit: () => formik.handleSubmit(), 
      getValues: () => formik.values,
    }));

    return (
      <div className="w-full relative">
        {active ? (
          <form onSubmit={formik.handleSubmit} className="w-full -mt-2">
            <InputFieldMna
              label="Date"
              id="inputValue"
              name="inputValue"
              type="date"
              placeholder="Date"
              showIcon
              bgColor="#fff"
              last={true}
              formik={formik}
              cancel={handleCancel}
              dateType="noconfirm"
            />
          </form>
        ) : (
          <div
           className="w-full ">
            <span className="text-xs-normal text-blue-900">{initialValue}</span>
          </div>
        )}
      </div>
    );
  }
);

export default EditableDateField;

