import React from 'react';

const AboutFundamental = () => {
  return (
    <div className="overflow-x-auto">
      <div className="min-w-full inline-block align-middle">
        <div className=" rounded-radius-lg overflow-hidden ">
          <table className="min-w-full dark:bg-primary-dark bg-white">
            <caption className="text-left  pt-4 text-lg-semibold text-blue-500  bg-white pb-2 pr-4 pl-3.5">
              About Fundamental
            </caption>
            <tbody>
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  Date Published:
                </td>
                <td className="px-3.5 text-sm-normal text-blue-900">
                  04.03.2024
                </td>
              </tr>
              <tr>
              <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
              Source Type:</td>
                <td className="px-3.5 text-sm-normal text-blue-900">
                  Article
                </td>
              </tr>
              <tr>
              <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
              Author:</td>
                <td className="px-3.5 text-sm-normal text-blue-900">
                  Amily Smith
                </td>
              </tr>
              <tr>
              <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
              Net Debit:</td>
                <td className="px-3.5 text-sm-normal text-blue-900">
                  3,799.8
                </td>
              </tr>
              <tr>
              <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
              Payment Method:
                </td>
                <td className="pl-4 pr-3.5 pt-2 pb-4 text-sm-normal text-blue-900">
                  American Express
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AboutFundamental;
