// React
import React from "react";
// Third party
import { Link, useLocation, useParams } from "react-router-dom";
// Images
import { PlusIcon } from "../../assets/icons";

interface BreadcrumbsProps {
  breadcrumbs: string[];
  icon?: string;
  addItem?: () => void;
  updateItem?: () => void;
  isSort?: boolean;
  options?: any[];
  selectedOption?: string;
  handleItemClick?: (option: string) => void;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  breadcrumbs,
  icon,
  isSort,
  options,
  selectedOption,
  addItem,
  updateItem,
  handleItemClick,
}) => {
  const { pathname } = useLocation();
  const { id } = useParams();

  return (
    <div className=" flex items-center justify-between gap-[30px]  flex-wrap">
      <ol className="list-none p-0 inline-flex text-[#181825]" >
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index} className="flex items-center gap-4 m-0">
            {index === 0 && icon && (
              <img
                src={icon}
                alt="icon"
                className="dark:brightness-[3.5] brightness-0 hidden"
              />
            )}
            {breadcrumbs.length === 1 ? (
              <div>
                <Link
                  to={pathname}
                  className="text-blue-900 dark:text-white text-base-semibold"

                >
                  {breadcrumb}
                </Link>
              </div>
            ) : (
              <div className={"flex gap-3 items-center"}>
                {index === 0 && (
                  <>
                    <span className="text-blue-900 dark:text-white text-lg-medium">
                      {breadcrumb}
                    </span>
                    <svg
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={"w-8 mr-3"}
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M4 12H20M20 12L16 8M20 12L16 16"
                          stroke="#B6B9C5"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </g>
                    </svg>
                  </>
                )}
                {index >= 1 && (
                  <div>
                    <span className="text-blue-900 text-lg-medium dark:text-white uppercase">
                      {breadcrumb}
                    </span>
                  </div>
                )}
              </div>
            )}
          </li>
        ))}
      </ol>
      <div className="flex items-center gap-[30px]">
        <div className="flex justify-between items-center gap-2 flex-wrap">
          {[
            "/notes",
            "/mnas",
            `/mna/${id}`,
            "/company",
            "/fundamental",
            "/old-mnas",
            `/old-mna/${id}`,
            `/fundamental/${id}`,
            "/regulatory",
            `/regulatory/${id}`,
          ].includes(pathname) && (
              <div
                data-testid='add-new'
                onClick={addItem}
                className="flex items-center gap-[8px] text-white text-xs-normal rounded-radius-lg cursor-pointer bg-blue-500 dark:bg-button-dark px-3 py-2 text-xs-medium"
              >
                <span>Add new</span>
                <PlusIcon />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
