// React
import React from 'react';
// Third party
import { Link } from "react-router-dom";
import moment from "moment/moment";
// Types
import { Mna } from "../../../interfaces/pages/variedInterfaces";

interface SecondListGroupProps {
  data: any;
}

const SecondListGroup: React.FC<SecondListGroupProps> = ({ data }) => {
  return (
    <div>
      <div >
        <div className="min-w-full inline-block align-middle shadow-shadow-md overflow-hidden rounded-radius-lg">
          <div className="overflow-hidden ">
            <table className="min-w-full  dark:bg-black bg-white">
              <caption 
              className="text-left text-lg-semibold dark:bg-black bg-white text-blue-500 p-squish-extra-sm">
                MNAs - Recently Modified
              </caption>
              <thead className="bg-grey-50 dark:bg-black dark:text-white">
              <tr className=' border-b-1 border-b-grey-200'>
              <th
                    scope="col"
                    className="p-squish-extra-sm text-start text-blue-900 text-sm-semibold w-1/3"
                    >
                    Mna Name
                  </th>
                  <th
                    scope="col"
                    className="p-squish-extra-sm text-start text-blue-900 text-sm-semibold w-1/3"
                    >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="p-squish-extra-sm text-start text-blue-900 text-sm-semibold w-1/3"
                    >
                    Date Modified
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-[#DCDBEE] align-top">
                {data.mnas?.map((mna: Mna, index: number) => (
                            <tr key={index} className=' border-b-1 border-b-grey-200'>
                                <td className="p-squish-extra-sm text-sm-normal break-words ">
                                <Link className="text-secondary" to={`/mna/${mna?.id}`}>
                        {mna?.name}
                      </Link>
                    </td>
                    <td className="p-squish-extra-sm text-sm-normal">
                    {mna.categoryName}
                    </td>
                    <td className="p-squish-extra-sm text-sm-normal">
                    {moment(mna.dateModified).format("MM/DD/YYYY hh:mm A")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondListGroup;
