import React from "react";

const CapitalizationSummary = () => {
  return (
    <div className=" overflow-x-auto rounded-radius-xl ">
      <div className=" min-w-full inline-block align-middle ">
        <div className="table-container">
          <table className="min-w-full dark:bg-primary-dark bg-white ">
            <caption className="text-left  text-lg-semibold bg-white text-blue-500  pt-4  pb-2 px-4">
              Capitalization Summary
            </caption>
            <tbody className="">
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  Current Price:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $144.00
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  Diluted Shares:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  42.8
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2  text-sm-normal text-blue-900">
                  Market Cap:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  6,189.0
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  Net Debit:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  3,799.8
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  Enterprice Value:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  10,144.6
                </td>
              </tr>
              <tr>
                <td className="pl-4 pr-3.5 pt-2 pb-4 text-sm-normal text-blue-900">
                  Net Deb/EBITDA:
                </td>
                <td className="px-3.5 pb-2 text-xs-normal text-blue-900 text-left">
                  5.2x
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CapitalizationSummary;
