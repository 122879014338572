// React
import React, { useEffect, useRef, useState } from "react";
// Third party
import ReactQuill from "react-quill";
// Components
import AsyncSelect from "../AsyncSelect";
import InputField from "../CommonInputs";
import TextEditor from "../TextEditor";
import FileUpload from "../FileUpload";
import RadioButton from "../RadioButton";
// OpenAPI
import {
  CompanyData,
  NoteAssociationDomainApi,
  TagDomainApi,
} from "../../openapi";
// Config
import { apiConfig } from "../ConfigurationApi/Configuration";
// Types
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  setIsAllowedToExpandContentNotesCreate,
  setIsAllowedToExpandCommentaryNotesCreate,
} from "../../features/textEditorExpand";

type NotesFormProps = {
  serverError: any;
  formik: any;
  sourceType: any;
  onEditorRef?: any;
  isMenuOpen: boolean;
};

export default function NotesForm(props: NotesFormProps) {
  const { formik, serverError, sourceType, onEditorRef, isMenuOpen } = props;
  const [mnaLoading, setMnaLoading] = useState(false);
  const $content = useRef<ReactQuill>(null);
  const $commentary = useRef<ReactQuill>(null);
  const $tag = useRef<any>(null);

  const [isTextEditorExpanded, setTextEditorIsExpanded] = useState(false);

  const dispatch = useDispatch();

  const isAllowedToExpandContentNotesCreate = useSelector(
    (state: RootState) => {
      return state.textEditorExpand.isAllowedToExpandContentNotesCreate;
    }
  );

  const isAllowedToExpandCommentaryNotesCreate = useSelector(
    (state: RootState) => {
      return state.textEditorExpand.isAllowedToExpandCommentaryNotesCreate;
    }
  );

  function handleCancelEdit() {
    dispatch(setIsAllowedToExpandContentNotesCreate(false));
    dispatch(setIsAllowedToExpandCommentaryNotesCreate(false));
  }

  useEffect(() => {
    return () => {
      handleCancelEdit();
    };
  }, [dispatch]);

  const fetchAssociations = async (inputValue: string): Promise<any[]> => {
    const api = new NoteAssociationDomainApi(apiConfig());
    try {
      const response = await api.searchDomain(inputValue);
      const data = response.data;
      const filteredOptions = (data as CompanyData[]).map((item) => ({
        ...item,
        value: item.id,
        label: `${item.name} - ${item.domainName}`,
      }));
      return filteredOptions;
    } catch (error) {
      expiredTokenValidation(error);
      return [];
    }
  };

  const fetchTags = async (inputValue: string): Promise<any[]> => {
    setMnaLoading(true);
    try {
      const api = new TagDomainApi(apiConfig());

      const response = await api.getTags(
        `${inputValue}`,
        undefined,
        undefined,
        undefined
      );

      const filteredOptions = response.data.content!.map((item: any) => ({
        value: item.id,
        label: item.tag,
      }));

      setMnaLoading(false);
      return filteredOptions;
    } catch (error) {
      expiredTokenValidation(error);
      setMnaLoading(false);
      return [];
    }
  };

  useEffect(() => {
    const editorWrap = document.getElementById("texdEditorWrap-Notes");
    if (editorWrap) {
      editorWrap.classList.add("no-global-styles");
    }

    return () => {
      if (editorWrap) {
        editorWrap.classList.remove("no-global-styles");
      }
    };
  }, []);

  return (
    <>
      {isTextEditorExpanded ? (
        <div
          className={` ${
            isMenuOpen ? "left-[284px]" : "left-[60px]"
          } fixed  top-0  w-full h-full bg-slate-600 opacity-30  z-[998] duration-500`}
        ></div>
      ) : null}
      <div className="py-6 ">
        {/* Ticker */}
        <div className="w-full mb-8">
          <AsyncSelect
            label="Tickers, Companies, or Subject"
            name="domains"
            id="domains"
            placeholder="Ticker"
            fetchOptions={fetchAssociations}
            formik={formik}
            loading={mnaLoading}
            error={""}
            isMulti
            bgColor="#FFF"
            last
            noOptionsMessage="No ticker found"
          />
        </div>

        <div className="mb-8 flex flex-col gap-[30px] xl:flex-row">
          {/*Title */}

          <div className="w-full xl:w-3/4">
            <InputField
              label="Title"
              id="title"
              name="title"
              type="text"
              placeholder="Title"
              serverError={serverError || ""}
              formik={formik}
              showIcon
              bgColor={"#fff"}
            />
          </div>

          {/* Sentiment */}
          <div className="w-full xl:w-1/4">
            <RadioButton
              label="Sentiment"
              id="sentiment"
              name="sentiment"
              serverError={serverError || ""}
              formik={formik}
              last
              options={[
                { value: "-1", label: "Negative" },
                { value: "0", label: "Neutral" },
                { value: "1", label: "Positive" },
              ]}
              selectedOption={formik.values.sentiment}
            />
          </div>
        </div>

        <div className="mb-8 flex flex-col gap-[30px] xl:flex-row">
          {/* Note Source Type */}

          <div className="w-full xl:w-1/4">
            <InputField
              label="Source Type"
              id="noteSourceTypeId"
              name="noteSourceTypeId"
              type="select"
              optionList={sourceType}
              placeholder="Source Type"
              serverError={serverError || ""}
              formik={formik}
              labelKey="name"
              valueKey="id"
              showIcon
              bgColor={"#fff"}
            />
          </div>

          {/* Source Detail */}
          <div className="w-full  xl:w-2/4">
            <InputField
              label="Source"
              id="sourceDetail"
              name="sourceDetail"
              type="text"
              placeholder="Enter here..."
              serverError={serverError || ""}
              formik={formik}
              showIcon
              bgColor={"#fff"}
            />
          </div>

          {/* Date Published */}

          <div className="w-full xl:w-1/4">
            <InputField
              label="Date Published"
              id="datePublished"
              name="datePublished"
              type="date"
              placeholder="Select date"
              serverError={serverError || ""}
              formik={formik}
              showIcon
              bgColor={"#fff"}
              time={true}
              last={true}
            />
          </div>
        </div>

        {/*Author */}

        <div className="mb-8 flex flex-col gap-[30px] xl:flex-row">
          <div className="w-full xl:w-1/3">
            <InputField
              label="Author1"
              id="author1"
              name="author1"
              type="text"
              placeholder="Enter here..."
              serverError={serverError || ""}
              formik={formik}
              showIcon={false}
              bgColor={"#fff"}
            />
          </div>
          <div className="w-full xl:w-1/3">
            <InputField
              label="Author2"
              id="author2"
              name="author2"
              type="text"
              placeholder="Enter here..."
              serverError={serverError || ""}
              formik={formik}
              showIcon={false}
              bgColor={"#fff"}
            />
          </div>
          <div className="w-full xl:w-1/3">
            <InputField
              label="Author3"
              id="author3"
              name="author3"
              type="text"
              placeholder="Enter here..."
              serverError={serverError || ""}
              formik={formik}
              showIcon={false}
              bgColor={"#fff"}
              last={true}
              onKeyDownCapture={(e) => {
                if (e.key === "Tab") {
                  e.preventDefault();
                  e.stopPropagation();
                  $content.current && $content.current.focus();
                }
              }}
            />
          </div>
        </div>

        {/*Content */}

        <div className="mb-8">
          <div
            className="relative"
            onKeyDownCapture={(e) => {
              if (
                e.key === "Tab" &&
                (e.target as HTMLElement).classList.contains("ql-editor")
              ) {
                e.preventDefault();
                e.stopPropagation();
                $commentary.current && $commentary.current.focus();
              }
            }}
          >
            <div id="texdEditorWrap-Notes">
              <TextEditor
                formik={formik}
                error={serverError?.content}
                id="content"
                name="content"
                quillRef={$content}
                label="Content"
                onEditorRef={onEditorRef}
                isAllowedToExpandTextEditor={
                  isAllowedToExpandContentNotesCreate
                }
                setIsAllowedToExpandTextEditor={
                  setIsAllowedToExpandContentNotesCreate
                }
                isMenuOpen={isMenuOpen}
                setTextEditorIsExpanded={setTextEditorIsExpanded}
                isTextEditorExpanded={isTextEditorExpanded}
              />
            </div>
          </div>
        </div>

        {/*Commentary */}

        <div className="mb-8">
          <div
            className="relative"
            onKeyDownCapture={(e) => {
              if (e.key === "Tab") {
                e.preventDefault();
                e.stopPropagation();
                $tag.current && $tag.current.focus();
              }
            }}
          >
            <TextEditor
              formik={formik}
              error={serverError?.commentary}
              id="commentary"
              name="commentary"
              quillRef={$commentary}
              label="Commentary"
              onEditorRef={onEditorRef}
              isAllowedToExpandTextEditor={
                isAllowedToExpandCommentaryNotesCreate
              }
              setIsAllowedToExpandTextEditor={
                setIsAllowedToExpandCommentaryNotesCreate
              }
              isMenuOpen={isMenuOpen}
              setTextEditorIsExpanded={setTextEditorIsExpanded}
              isTextEditorExpanded={isTextEditorExpanded}
            />
          </div>
        </div>

        {/* Tags */}

        <div className="w-full mb-8">
          <AsyncSelect
            label="Tags"
            name="tags"
            id="tags"
            placeholder="Tags"
            fetchOptions={fetchTags}
            formik={formik}
            loading={mnaLoading}
            error={""}
            isMulti
            bgColor="#FFF"
            tagRef={$tag}
            last
            noOptionsMessage="No tag found"
            tags
          />
        </div>

        {/* File Upload  */}
        <div className="flex justify-between items-center">
          <label
            htmlFor="File"
            className="text-base  text-medium mb-2 flex gap-1"
          >
            File
          </label>
        </div>
        <div className="border border-[#E4E3ED] rounded-lg">
          <div className="px-[23px] py-[30px]">
            <FileUpload formik={formik} />
          </div>
        </div>
      </div>
    </>
  );
}
