// React
import React from "react";

interface NotesData {
  sourceDetail?: string;
  author1?: string;
  sentiment?: -1 | 0 | 1;
}

interface NoteMetadataProps {
  notesData: NotesData;
}

const NoteMetadata: React.FC<NoteMetadataProps> = ({ notesData }) => {

  const getSourceAuthorText = () => {
    if (notesData?.sourceDetail && notesData?.author1) {
      return `${notesData.sourceDetail} - ${notesData.author1}`;
    }
    if (notesData?.author1) {
      return notesData.author1;
    }
    if (notesData?.sourceDetail) {
      return notesData.sourceDetail;
    }
    return "";
  };

  const getSentimentText = () => {
    switch (notesData?.sentiment) {
      case -1:
        return "Negative";
      case 0:
        return "Neutral";
      case 1:
        return "Positive";
      default:
        return null;
    }
  };

  return (
    <div>
      {/* Source and Author */}
      <p className="mt-2 text-md text-[#888888] text-sm-normal leading-6 lg:mt-0 lg:text-end">
        {getSourceAuthorText()}
      </p>

      {/* Sentiment */}
      {(notesData?.sentiment === -1 ||
        notesData?.sentiment === 0 ||
        notesData?.sentiment === 1) && (
          <p className="text-[#888888] text-sm-normal leading-6 lg:mt-0 lg:text-end">
            Sentiment: {getSentimentText()}
          </p>
        )}
    </div>
  );
};

export default NoteMetadata;
