


import React, { useState, useRef, useEffect } from "react";

interface Option {
  id: number;
  name: string;
}

interface CategorySelectProps {
  name: string;
  value: number | string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  options: Option[];
  className?: string;
  placeholder?: string;
}

function createSyntheticEvent(
  name: string,
  value: number
): React.ChangeEvent<HTMLInputElement | HTMLSelectElement> {
  return {
    target: { name, value },
    currentTarget: { name, value },
    bubbles: false,
    cancelable: false,
    defaultPrevented: false,
    preventDefault: () => {},
    isDefaultPrevented: () => false,
    stopPropagation: () => {},
    persist: () => {},
    nativeEvent: new Event("change"),
  } as unknown as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>;
}

const CategorySelect: React.FC<CategorySelectProps> = ({
  name,
  value,
  onChange,
  options,
  className,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const selectRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<Array<HTMLLIElement | null>>([]);

  const selectedCategory = options.find((category) =>
    category.id === (typeof value === "string" ? parseInt(value) : value)
  );

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!isOpen) return;

    const items = placeholder ? [null, ...options] : [...options];
    const lastIndex = items.length - 1;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex((prev) =>
          prev >= lastIndex ? 0 : prev + 1
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prev) =>
          prev <= 0 ? lastIndex : prev - 1
        );
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < items.length) {
          const item = items[highlightedIndex];
          if (item) {
            onChange(createSyntheticEvent(name, item.id));
          } else if (placeholder) {
            onChange(createSyntheticEvent(name, 0));
          }
          setIsOpen(false);
        }
        break;
      case "Escape":
        e.preventDefault();
        setIsOpen(false);
        break;
    }
  };

  useEffect(() => {
    if (isOpen && highlightedIndex >= 0) {
      itemsRef.current[highlightedIndex]?.scrollIntoView({
        block: "nearest",
      });
    }
  }, [highlightedIndex, isOpen]);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }
  }, [isOpen, highlightedIndex]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setHighlightedIndex(-1);
    }
  }, [isOpen]);

  return (
    <div
      ref={selectRef}
      className={`relative ${className || ""} text-xs-normal z-110`}
    >
      <button
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
        className="w-full mt-[6px] px-2 py-1 border border-[#4A8DF1] rounded-[2px] text-blue-900 focus:outline-none text-xs-normal text-left truncate min-h-[27px]"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        {selectedCategory?.name || (placeholder ?? "")}
      </button>
      {isOpen && (
        <ul
          className={`absolute ${
            placeholder ? "" : "pt-2"
          } mt-2 w-full bg-white shadow-shadow-lg2 rounded-radius-lg max-h-60 overflow-auto !pl-0 min-w-[210px] z-[110]`}
          role="listbox"
        >
          {placeholder && (
            <li
              ref={(el) => (itemsRef.current[0] = el)}
              key={0}
              onClick={() => {
                onChange(createSyntheticEvent(name, 0));
                setIsOpen(false);
              }}
              className={`cursor-pointer hover:bg-blue-100 hover:text-blue-primary text-blue-900 mx-2 mb-0 first:mt-2 list-none p-2 rounded-radius-lg ${
                highlightedIndex === 0 ? "bg-blue-100" : ""
              }`}
              role="option"
              aria-selected={value === 0}
            >
              {placeholder}
            </li>
          )}
          {options.map((category, index) => {
            const itemIndex = placeholder ? index + 1 : index;
            return (
              <li
                ref={(el) => (itemsRef.current[itemIndex] = el)}
                key={category.id}
                onClick={() => {
                  onChange(createSyntheticEvent(name, category.id));
                  setIsOpen(false);
                }}
                className={`cursor-pointer hover:bg-blue-100 hover:text-blue-primary text-blue-900 mx-2 my-0 list-none p-2 last:mb-2 rounded-radius-lg ${
                  highlightedIndex === itemIndex ? "bg-blue-100" : ""
                }`}
                role="option"
                aria-selected={value === category.id}
              >
                {category.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default CategorySelect;