// React
import React, { createContext, useContext, useState } from "react";
// Third party
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
// Routes
import { PrivateAuthenticated, PublicAuthenticated } from "./routes/Protected";
import { protectedRoutes, publicRoutes } from "./routes/Route";
// Styles
import "./App.css";
import TabContainer from "./components/Tabs/Container";
// store
import { store } from "./store/store";

const ThemeContext = createContext({ theme: "light", toggleTheme: () => {} });

function App() {
  const themeMode = localStorage.getItem("theme") as "light" | "dark" | null;
  const [theme, setTheme] = useState<"light" | "dark">(themeMode || "light"); // Theme state
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme); // Save selected theme in local storage
  };
  return (
    <Provider store={store}>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <Router
          future={{
            v7_startTransition: true,
            v7_relativeSplatPath: true,
          }}
        >
          <Toaster position="bottom-center" reverseOrder={false} />
          <Routes>
            {protectedRoutes.map(({ path, element: Component }) => (
              <Route
                key={path}
                path={path}
                element={
                  <PrivateAuthenticated
                    isMenuOpen={isMenuOpen}
                    setIsMenuOpen={setIsMenuOpen}
                  >
                    <Component />
                  </PrivateAuthenticated>
                }
              />
            ))}
            {publicRoutes.map(({ path, element: Component }) => (
              <Route
                key={path}
                path={path}
                element={
                  <PublicAuthenticated>
                    <Component />
                  </PublicAuthenticated>
                }
              />
            ))}
            <Route
              path="/settings/*"
              element={
                <PrivateAuthenticated
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                >
                  <SettingsRoutes isMenuOpen={isMenuOpen} />
                </PrivateAuthenticated>
              }
            />
            <Route path="/settings" element={<Navigate to="profile" />} />
          </Routes>
        </Router>
      </ThemeContext.Provider>
    </Provider>
  );
}
export const useTheme = () => useContext(ThemeContext);

const SettingsRoutes = ({isMenuOpen}:any) => {
  return <TabContainer   isMenuOpen={isMenuOpen}
  />;
};

export default App;
