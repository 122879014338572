// React
import React, {useCallback, useState} from "react";
// Third party
import {useNavigate} from "react-router-dom";
// Components
import {Columns} from "../../components/Columns/Columns";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Table from "./components/MnaFundamentalTable";
// Types
import {HandleMenuProps, Sort} from "../../interfaces/pages/variedInterfaces";
// Images
import fundamentalIcon from "../../assets/svg/blue-fundamental.svg";
// Services
import {fundamentalServices} from "./services/fundamentalServices";

const FundamentalList: React.FC<HandleMenuProps> = ({
  isMenuOpen,
}) => {
  const navigate = useNavigate();
  const columnsInstance = new Columns();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchFundamentalList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      setLoading(true);
      try {
          return await fundamentalServices.fetchFundamentalList(pageIndex, pageSize, sortBy);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return (
    <>
      <div
         className={`py-4 bg-white  flex flex-col ${
          isMenuOpen ? "pl-[282px]" : "pl-[60px]"
        } duration-500`}
      >
        <div className="mx-4">
        <Breadcrumbs
          breadcrumbs={["Fundamental"]}
          icon={fundamentalIcon}
          addItem={() => navigate("/add-fundamental")}
        />
        </div>
        <div className="mt-[16px]">
          <Table
          isMenuOpen={isMenuOpen}
            columns={columnsInstance.FundamentalList(navigate)}
            fetchData={fetchFundamentalList}
            loading={loading}
            typeForPagination="setFundamentalListPageIndex"
          />
        </div>
      </div>
    </>
  );
};

export default FundamentalList;
