import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { MnaDomainApi } from "../../openapi";
import { apiConfig } from "../ConfigurationApi/Configuration";
import { editClosingDateLocally } from "./EditableFieldsUtilities/editableFieldsUtils";

interface EditableOutDatesCommentProps {
  testId?: string;
  fieldName: string;
  data?: string;
  fullData: any;
  dateIndex: number;
  setFetchMnaDetails: (flag: boolean) => void;
  setLocalData: (data: any) => void;
  fetchMnaData: () => Promise<void>;
  active?: boolean;
  setCommentValue?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export interface CommentEditorHandle {
  submitComment: (e?: React.FormEvent) => void;
}

const EditableOutDatesComment = forwardRef<
  CommentEditorHandle,
  EditableOutDatesCommentProps
>(
  (
    {
      testId,
      fieldName,
      data,
      fullData,
      dateIndex,
      setFetchMnaDetails,
      setLocalData,
      fetchMnaData,
      active,
      setCommentValue,
      onKeyDown,
    },
    ref
  ) => {
    const [comment, setComment] = useState(data || "");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setComment(data || "");
    }, [data]);

    const handleSubmit = async (e?: React.FormEvent) => {
      e?.preventDefault();
      setLoading(true);

      try {
        const dateEvent = fullData?.[fieldName]?.[dateIndex]?.dateEvent;
        editClosingDateLocally(
          fieldName,
          dateIndex,
          dateEvent,
          comment,
          setLocalData
        );

        const patchValue = {
          edit: {
            [String(fullData?.[fieldName]?.[dateIndex]?.id)]: {
              mnaId: fullData?.id,
              comment: comment,
            },
          },
        };

        const jsonPatch = [
          {
            op: "replace",
            path: `/${fieldName}`,
            value: patchValue,
          },
        ];
        const api = new MnaDomainApi(apiConfig());
        await api.partiallyUpdateMna(String(fullData?.id), jsonPatch);
        await fetchMnaData();
        setFetchMnaDetails(true);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      submitComment: handleSubmit,
    }));

    return (
      <div
        data-testid={testId}
        className="flex relative text-xs-normal  text-blue-900 mr-4 w-full"
      >
        {active ? (
          <form onSubmit={handleSubmit} className="w-full">
            <input
              type="text"
              value={comment}
              onKeyDown={onKeyDown}
              onChange={(e) => {
                setCommentValue?.(e.target.value);
                setComment(e.target.value);
                editClosingDateLocally(
                  fieldName,
                  dateIndex,
                  fullData[fieldName][dateIndex]?.dateEvent,
                  e.target.value,
                  setLocalData
                );
              }}
              className="rounded-md px-2 py-[2px]  border border-blue-500 focus:outline-none w-full"
            />
          </form>
        ) : (
          <div className="cursor-pointer w-full h-full mt-[3px]  overflow-hidden text-ellipsis break-words">
            <span className="">{comment}</span>
          </div>
        )}
      </div>
    );
  }
);

export default EditableOutDatesComment;
