// OpenAPI
import { expiredTokenValidation } from "../../../api/expiredTokenValidation";
import {
  FundamentalDomainApi,
  SectorDomainApi,
  TagDomainApi,
} from "../../../openapi";
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import { getApiData } from "../../../controller/getApiDataConfiguration";
// Types
import {SearchResultTag, Sort} from "../../../interfaces/pages/variedInterfaces";
// Helpers
import { getSortArray } from "../../../helpers/getSortArray";
import {showAlert} from "../../../components/Modal/AlertModal";

interface FundamentalData {
  dateIpo: Date | null;
  whatMattersId?: string;
  tags?: any[];
  files?: any;
}


  interface Pageable {
    page: number;
    size: number;
    sort: string[];
  }


 export async function getFundamentalNotes(id: string, pageable: Pageable, setNotes: any, setTotalPages:any) {
    try {
      const notes = await fundamentalServices.fetchFundmentalNotes(
        id,
        pageable
      );
      setNotes(notes.content);
      setTotalPages(notes.page.totalElements)
    } catch (error) {
      console.error("Error fetching fundamental notes:", error);
    }
  }


export const fundamentalServices = {
  fetchFundamentalList: async (
    pageIndex: number,
    pageSize: number,
    sortBy: Sort[]
  ) => {
    try {
      const api = new FundamentalDomainApi(apiConfig());
      const sortArray = getSortArray(sortBy);

      const response = await api.getFundamentals(
        pageIndex,
        pageSize,
        sortArray
      );
      const content = new getApiData();
      const data = await content.getSectorDomainApi(response);
      return { ...response.data, data };
    } catch (error) {
      expiredTokenValidation(error);
    }
  },

  fetchSectorNameById: async (sectorId: number) => {
    try {
      const api = new SectorDomainApi(apiConfig());
      const response = await api.getSectorById(sectorId);
      return response.data?.name || "";
    } catch (error) {
      expiredTokenValidation(error);
    }
  },

  fetchSectorList: async (id:string  | undefined) => {
    try {
      const api = new getApiData();
      const response = await api.getSectorDomainApi(id);
      return response?.data?.content!.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  fetchFundamentalData: async (id: string): Promise<FundamentalData> => {
    try {
      const api = new FundamentalDomainApi(apiConfig());
      const response = await api.getFundamentalById(id);
      return {
        ...response.data,
        dateIpo: response.data.dateIpo ? new Date(response.data.dateIpo) : null
      };
    } catch (error) {
      expiredTokenValidation(error);
      throw error;
    }
  },

  createFundamental: async (data: any) => {
    try {
      const api = new FundamentalDomainApi(apiConfig());
      return await api.createFundamental(data);
    } catch (error) {
      expiredTokenValidation(error);
      throw error;
    }
  },

  updateFundamental: async (id: string, data: any) => {
    try {
      const api = new FundamentalDomainApi(apiConfig());
      return await api.updateFundamental(id, data);
    } catch (error) {
      expiredTokenValidation(error);
      throw error;
    }
  },

  fetchTags: async (inputValue: string): Promise<any[]> => {
    try {
      const api = new TagDomainApi(apiConfig());
      const response = await api.getTags(
        inputValue,
        undefined,
        undefined,
        undefined
      );

      return (response.data.content as SearchResultTag[]).map(
        (item) => ({
          value: item.id,
          label: item.tag,
        })
      );
    } catch (error) {
      console.error("Error fetching tags:", error);
      throw error;
    }
  },

  fetchFundmentalNotes: async(id: string, pageable:Pageable):Promise<any> => {
    try{
      const api = new FundamentalDomainApi(apiConfig());
      const response = await api.getFundamentalNotes(id, pageable);
      return response.data
    }catch(error){
      console.error("Error fetching notes", error);
    }

  }

  

};
