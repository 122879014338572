// React
import React from "react";
// Images
import EyeClose from "../../../assets/svg/eyesclose.svg";
import EyeOpen from "../../../assets/svg/eyesopen.svg";

interface PasswordInputProps {
  id: string;
  label: string;
  placeholder?: string;
  type: string;
  value: string;
  error?: string;
  touched?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onToggle: () => void;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  id,
  label,
  placeholder = "Enter Password",
  type,
  value,
  error,
  touched,
  onChange,
  onBlur,
  onToggle,
}) => {
  return (
    <div className="flex flex-col">
      <label
        className="block text-blue-900 text-base-normal mb-2"
        htmlFor={id}
      >
        {label}
      </label>
      <div className="relative">
        <input
          id={id}
          name={id}
          type={type}
          className="placeholder:text-placeholder text-placeholder w-full rounded-radius-lg bg-transparent py-4 px-4 pr-12 outline-none focus:border-primary border-1 border-gray-300 focus-visible:shadow-none"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          data-testid={`${id}-input`}
         
        />
        <span
          className="absolute right-7 top-5 cursor-pointer"
          onClick={onToggle}
          data-testid={`${id}-toggle`}
        >
          {type === "password" ? (
            <img src={EyeClose} alt="Show Password" />
          ) : (
            <img src={EyeOpen} alt="Hide Password" />
          )}
        </span>
      </div>
      {error && touched && (
        <div
          className="py-1 text-sm font-medium text-danger"
          data-testid={`${id}-error`}
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default PasswordInput;
