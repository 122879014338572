// src/components/Modal.tsx
import React from "react";

interface LogoutModalProps  {
  show: boolean;
  onClose: () => void;
  onLogout: () => void;
}

const LogoutModal: React.FC<LogoutModalProps > = ({ show, onClose, onLogout }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-primary-dark bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg dark:bg-table-dark">
        <h2 className="text-lg-semibold mb-2">Confirm Logout</h2>
        <p className="mb-4 text-sm-normal">Are you sure you want to logout?</p>
        <div className="flex justify-end gap-4">
          <button
            className="py-2 border-1 border-gray-300 text-secondary dark:bg-button-dark dark:text-white text-base font-medium rounded-md px-8"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="py-2  text-[#fff] bg-blue-500 text-base font-medium rounded-md px-8"
            onClick={onLogout}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutModal;
