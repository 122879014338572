const DomainList = ({ domains }: { domains: any[] }) => (
  <div className="flex flex-wrap">
    {domains?.map((domain: any, index: number) => (
      <h2 key={index} className=" ">
        {domain?.domainName === 'Fundamental' || domain?.domainName === 'MNA' ? (
          <a
            href={domain?.domainName === 'Fundamental' ? `/fundamental/${domain?.id}` : `/mna/${domain?.id}`}
            className="text-blue-900 text-base-semibold"
          >
            {domain.name}
          </a>
        ) : (
          <button type="button"
          className="text-blue-900 text-base-semibold"
          >
            {domain.name}
          </button>
        )}
        {`${index !== domains.length - 1 ? ' |' : ''}`}&nbsp;
      </h2>
    ))}
  </div>
);

export default DomainList;
