// React
import React, { useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Images
import dashboardIcon from "../../assets/svg/blue-dashboard.svg";
// Services
import {
  MigrationControl,
  MigrationStatus,
} from "./services/migrationServices";
// Components
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { MigrationButton } from "./components/DataMigrationButton";
import { MigrationCard } from "./components/DataMigrationCard";
// Types
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";

export type Status = "loading" | "completed" | "not-started" | "failed";

export default function DataMigration({
  isMenuOpen,
  setIsMenuOpen,
}: HandleMenuProps) {
  const [migrationProgress, setMigrationProgress] = useState<{
    [key: string]: any;
  }>({});
  const [status, setStatus] = useState<{ [key: string]: Status }>({});
  const [controller, setController] = useState<{
    [key: string]: AbortController;
  }>({});
  const role = localStorage.getItem("role");
  const navigate = useNavigate();

  const startMigration = async (domain: string) => {
    const migrationItem = MigrationControl.find(
      ({ title }) => title === domain
    );
    if (!migrationItem) return;

    setStatus((prevStatus) => ({ ...prevStatus, [domain]: "loading" }));
    const ctrl = new AbortController();
    setController((prev) => ({ ...prev, [domain]: ctrl }));

    setMigrationProgress((prevProgress) => ({
      ...prevProgress,
      [domain]: {
        id: 0,
        name: "Temporary",
        progress: 0,
        timeElapsed: 0,
        timeRemaining: 0,
      },
    }));

    const progressInterval = setInterval(() => {
      setMigrationProgress((prevProgress) => {
        const currentProgress = prevProgress[domain]?.progress || 0;
        const randomIncrement = Math.random() * 0.4 + 0.1;
        const newProgress = Math.min(100, currentProgress + randomIncrement);

        if (newProgress === 100) {
          setStatus((prevStatus) => ({ ...prevStatus, [domain]: "completed" }));
          clearInterval(progressInterval);
        }

        return {
          ...prevProgress,
          [domain]: {
            ...prevProgress[domain],
            progress: parseFloat(newProgress.toFixed(1)),
          },
        };
      });
    }, 100);

    try {
      const response = await migrationItem.apiFunction!({
        signal: ctrl.signal,
      });

      if (response.status === 200) {
        setMigrationProgress((prevProgress) => ({
          ...prevProgress,
          [domain]: { ...prevProgress[domain], progress: 100 },
        }));
        setStatus((prevStatus) => ({ ...prevStatus, [domain]: "completed" }));
      } else {
        setStatus((prevStatus) => ({ ...prevStatus, [domain]: "failed" }));
      }

      clearInterval(progressInterval);
    } catch (error: any) {
      clearInterval(progressInterval);
      setStatus((prevStatus) => ({ ...prevStatus, [domain]: "failed" }));
      if (error?.name !== "AbortError")
        console.error("Error during migration:", error);
    }
  };

  return (
    <React.Fragment>
      {role === "ROLE_ADMIN" ? (
        <div
          className={`py-4 pr-4 flex flex-col gap-4 ${
            isMenuOpen ? "pl-[300px]" : "pl-[76px]"
          } duration-500`}
        >
          <Breadcrumbs breadcrumbs={["Data Migration"]} icon={dashboardIcon} />
          <div className="flex flex-col gap-4 ">
            <div className="bg-white rounded-lg p-4 shadow-shadow-md">
              <h3 className="text-lg-semibold text-blue-500 mb-4">Migration Control</h3>
              <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-4">
                {MigrationControl.map((item) => (
                  <MigrationButton
                    key={item.id}
                    item={item}
                    status={status[item.title] || "not-started"}
                    startMigration={startMigration}
                  />
                ))}
              </div>
            </div>
            <div className="bg-white rounded-lg p-4 shadow-shadow-md">
              <h3 className="text-lg-semibold text-blue-500 mb-4">Migration Status</h3>
              <div className="grid gap-4 lg:grid-cols-2">
                {MigrationStatus.map(({ title, id, icon }) => {
                  const progressData = migrationProgress[title] || {
                    progress: 0,
                    timeElapsed: 0,
                    timeRemaining: 0,
                  };
                  return (
                    <MigrationCard
                      key={id}
                      status={status[title] || "not-started"}
                      id={id}
                      title={title}
                      icon={icon}
                      timeElapsed={progressData.timeElapsed}
                      timeRemaining={progressData.timeRemaining}
                      progress={progressData.progress}
                      startMigration={startMigration}
                      controller={controller[title]}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen">
          <h2 className="text-5xl font-extrabold">401</h2>
          <h2 className="text-5xl font-extrabold">UNAUTHORIZED</h2>
          <p
            className="text-blue-primary mt-5 cursor-pointer hover:underline"
            onClick={() => navigate(-1)}
          >
            Go back
          </p>
        </div>
      )}
    </React.Fragment>
  );
}
