// React
import React, { useRef } from "react";
// Thrid party
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// Components
import Alerts from "../components/Alerts";
// There
import { useTheme } from "../App";
// Data
import { navbarData } from "../data/navbarData";
// Images
import DarkLogo from "../assets/images/logo_dark.png";
import LightLogo from "../assets/images/logo_light.png";
import DarkLogoSm from "../assets/images/logo_dark_sm.png";
import LightLogoSm from "../assets/images/logo_light_sm.png";
import ArrowLeft from "../assets/icons/arrow-left.svg";
import ArrowRight from "../assets/icons/arrow-right.svg";

// Icons
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserMenuDropdown from "../components/ProfileMenu";
import SearchDialog from "../components/SearchDialog/SearchDialog";
//Types
import { HandleMenuProps } from "../interfaces/pages/variedInterfaces";
import { useDispatch } from "react-redux";
// Redux
import { resetErrorsListPage } from "../features/errorListPagination";
import { resetFilesListPage } from "../features/filesListPagination";
import { resetFundamentalListPage } from "../features/fundamentalListPagination";
import { resetMnaListPage } from "../features/mnaListPagination";
import { resetNotesForFundamentalPage } from "../features/notesForFundamentalPagination";
import { resetNotesForMnaPage } from "../features/notesForMnaPagination";
import { resetNotesListPage } from "../features/notesListPagination";
import { resetRegulatoryListPage } from "../features/regulatoryListPagination";
import { resetRegulatoryNotesPage } from "../features/regulatoryNotesPagination";

const Navbar: React.FC<HandleMenuProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const role = localStorage.getItem("role");
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigateDashboard = () => {
    navigate("/dashboard");
  };

  const handleResetPage = () => {
    dispatch(resetErrorsListPage());
    dispatch(resetFundamentalListPage());
    dispatch(resetFilesListPage());
    dispatch(resetMnaListPage());
    dispatch(resetNotesForFundamentalPage());
    dispatch(resetNotesForMnaPage());
    dispatch(resetNotesListPage());
    dispatch(resetRegulatoryListPage());
    dispatch(resetRegulatoryNotesPage());
  };

  const filteredNavbarData =
    role === "ROLE_ADMIN"
      ? navbarData
      : navbarData.filter(
          (item) =>
            item.pathname !== "error" && item.pathname !== "data-migration"
        );

  return (
    <header className="sticky top-0  bg-[#FFFFFF] dark:bg-black z-[99999]">
      <div
        className={`flex flex-wrap items-center justify-between mx-auto py-4 px-[30px] duration-500 shadow-shadow fixed bg-white top-0  z-[] w-full ${
          isMenuOpen ? "w-[calc(100%-278px)] pl-[250px]" : "w-[calc(100%-62px)]"
        }`}
      >
        <div className="flex items-center gap-8">
          {/* Sidebar */}
          <div ref={menuRef}>
            <div
              className={`${
                isMenuOpen
                  ? "translate-x-0 w-[284px]"
                  : " w-[60px] translate-x-0 "
              } fixed top-[0px] flex flex-col justify-between left-0 h-full transition-all duration-500 transform bg-[#21263C] shadow-lg peer-checked:translate-x-0 
              dark:bg-blue-900 overflow-hidden`}
            >
              <div className="h-full bg-blue-900">
                <div className="space-y-1 pb-3 relative h-full">
                  <div className="w-full bg-blue-900">
                    <div
                      className={`mb-[24px] flex items-center  pl-[15px] transition-all duration-500  relative overflow-hidden ${
                        isMenuOpen ? "w-[153px]" : "w-[60px]"
                      } h-[60px] 	dark:border-white`}
                    >
                      <img
                        src={theme.theme === "light" ? DarkLogo : LightLogo}
                        alt="Logo"
                        className={`h-[40px]  object-cover object-left absolute  transition-opacity duration-500 cursor-pointer ${
                          isMenuOpen ? "opacity-100" : "opacity-0"
                        }`}
                        onClick={navigateDashboard}
                      />
                      <img
                        src={theme.theme === "light" ? DarkLogoSm : LightLogoSm}
                        alt="Small Logo"
                        className={`h-[40px] object-cover object-left absolute  transition-opacity duration-500 cursor-pointer ${
                          !isMenuOpen ? "opacity-100" : "opacity-0"
                        }`}
                        onClick={navigateDashboard}
                      />
                    </div>
                  </div>
                  {filteredNavbarData.map((item, index) => (
                    <Link
                      key={`navbar-${item.title}-${index}`}
                      to={`/${item.pathname}`}
                      onClick={handleResetPage}
                      className={`${
                        isMenuOpen ? "pl-[7px]  pr-8" : "pl-[7px]"
                      } ${
                        pathname === `/${item.pathname}`
                          ? "text-[#F2F1FD] bg-blue-500 dark:text-[#F2F1FD] dark:bg-[#4A8DF1] rounded-md"
                          : "text-[#F2F1FD] "
                      } block py-2 2xl:py-4 text-base font-medium hover:bg-blue-500 rounded-md transition-all duration-500 ease-out mx-[10px]`}
                    >
                      <div className={`flex gap-[6px] items-center`}>
                        <div className="pl-[0px]">
                          <img
                            src={item.lightIcon}
                            alt="active"
                            className="w-[24px] h-[24px]"
                          />
                        </div>
                        <div
                          className={`transition-opacity duration-500 pl-[14px] ${
                            isMenuOpen ? "opacity-100" : "opacity-0"
                          } absolute left-12`}
                        >
                          <p className={`text-[#393C51}] text-sm-normal`}>
                            {item.title}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                  <button
                    type="button"
                    className="bg-[#181C2E] w-full py-[8px] absolute bottom-[0px] flex justify-center"
                    onClick={toggleMenu}
                  >
                    <img
                      src={isMenuOpen ? ArrowRight : ArrowLeft}
                      alt="toggle menu"
                      className="h-5 dark:invert"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center md:order-2 w-4/6 justify-end gap-[32px]">
          <SearchDialog />
          {/* DARK MODE TOGGLE */}
          {/* <div className="cursor-pointer" onClick={theme.toggleTheme}>
            {theme.theme === "dark" ? (
              <FontAwesomeIcon
                icon={faToggleOn}
                className="text-[#808080] text-[20px]"
              />
            ) : (
              <FontAwesomeIcon icon={faToggleOff} className="text-[20px]" />
            )}
          </div> */}
          <div>
            <Alerts className="w-[30px] h-[30px]" isIcon />
          </div>
          <div className="2xl:block">
            <UserMenuDropdown />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
