import React, { useEffect } from "react";
import DeleteIcon from "../../assets/svg/bin.svg";

interface ModalProps {
  title: string;
  onClose: () => void;
  onConfirm: () => void;
}

const DeleteModalMNADates: React.FC<ModalProps> = ({
  title,
  onClose,
  onConfirm,
}) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onConfirm();
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [onConfirm]);

  return (
    <div className="absolute inset-[1px] flex items-center justify-center bg-black bg-opacity-30 rounded-radius-md">
      <div className="bg-white rounded-[12.27px] w-[220px]">
        <div className="flex justify-center flex-col pt-[10px] px-4 pb-4">
          <div className="mx-auto flex p-3 flex-shrink-0 items-center justify-center rounded-full bg-gray-200">
            <img
              src={DeleteIcon}
              alt="Delete contact"
              className="h-full w-[17px] object-contain"
            />
          </div>
          <div className="text-center p-0">
            <h3
              className="text-sm-normal text-center text-black mt-[10px]"
              id="modal-title"
            >
              {title}
            </h3>
          </div>
        </div>
        {/* Buttons */}
        <div className="px-4 pb-[10px] flex justify-center">
          <button
            onClick={onConfirm}
            className="p-squish-extra-sm text-[#fff] bg-[#3B97FF] text-xs-normal rounded-lg mr-3"
          >
            Delete
          </button>
          <button
            onClick={onClose}
            className="p-squish-extra-sm text-black border-1 border-gray-200 text-xs-normal rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModalMNADates;
