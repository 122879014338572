// React
import React, { useEffect } from "react";
// Components
import EditableFieldMna from "../../../components/EditableFields/EditableField";
// Images
import Expand from "./../../../assets/svg/expand.svg";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setIsAllowedToExpandCompanyInformation } from "../../../features/textEditorExpand";

interface CompanyDescriptionTabProps {
  data: any;
  fetchMnaData: () => void;
  isTextEditorExpanded: boolean;
  setTextEditorIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isMenuOpen: boolean;
}

const CompanyDescriptionTab: React.FC<CompanyDescriptionTabProps> = ({
  data,
  fetchMnaData,
  isTextEditorExpanded,
  setTextEditorIsExpanded,
  isMenuOpen,
}) => {
  const dispatch = useDispatch();
  const isAllowedToExpandCompanyInformation = useSelector(
    (state: RootState) => {
      return state.textEditorExpand.isAllowedToExpandCompanyInformation;
    }
  );

  useEffect(() => {
    return () => {
      dispatch(setIsAllowedToExpandCompanyInformation(false));
    };
  }, [dispatch]);

  const handleFetchMnaData = async () => {
    fetchMnaData();
  };
  return (
    <table data-test-id="tab-content-mna" className="w-full">
      <caption className="flex justify-between text-left text-lg-medium text-blue-500 pt-4 px-4">
        <span> Company Description</span>
        {isAllowedToExpandCompanyInformation ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              setTextEditorIsExpanded((prev: boolean) => !prev);
            }}
          >
            <img src={Expand} alt="expand" />
          </div>
        ) : null}
      </caption>
      <tbody className="">
        <tr>
          <td className="pl-4 pr-3.5  dark:text-white text-xs-medium text-blue-900 align-top">
            <p className="mt-[10px]">Description:</p>
          </td>
        </tr>
        <tr>
          <td>
            <div
              className={`${
                isTextEditorExpanded
                  ? `fixed bg-white top-[92px] right-[16px] rounded-radius-lg duration-500 transition-[width] px-2 pt-2  ${
                      isMenuOpen
                        ? "w-[calc(100%-316px)] "
                        : "w-[calc(100%-92px)]"
                    }   z-[999] `
                  : " px-2"
              }`}
            >
              {isTextEditorExpanded ? (
                <div className="flex justify-between text-left text-lg-medium text-blue-500 pt-2 px-2">
                  <span> Company Description</span>
                  {isAllowedToExpandCompanyInformation ? (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setTextEditorIsExpanded((prev: boolean) => !prev);
                      }}
                    >
                      <img src={Expand} alt="expand" />
                    </div>
                  ) : null}
                </div>
              ) : null}

              <EditableFieldMna
                data={data?.description}
                fieldtype="textEditor"
                fieldName="description"
                fetchMnaData={handleFetchMnaData}
                setTextEditorIsExpanded={setTextEditorIsExpanded}
                isTextEditorExpanded={isTextEditorExpanded}
                editorFor="mna"
              />
            </div>
          </td>
        </tr>
        <tr>
          <td className="pl-4 pr-3.5 text-xs-medium text-blue-900 dark:text-white align-top">
            <p className="mt-2">Competitors:</p>
          </td>
        </tr>
        <tr className="w-full">
          <td>
            <div className="w-full px-2">
              <EditableFieldMna
                data={data?.competitors}
                fieldtype="text"
                fieldName="competitors"
                fetchMnaData={handleFetchMnaData}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td className="pl-4 pr-3.5 pt-2.5  dark:text-white text-xs-medium text-blue-900 align-top">
            <p className="">Customers:</p>
          </td>
        </tr>
        <tr className="w-full">
          <td>
            <div className="w-full px-2 pb-2">
              <EditableFieldMna
                data={data?.customers}
                fieldtype="text"
                fieldName="customers"
                fetchMnaData={handleFetchMnaData}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CompanyDescriptionTab;
