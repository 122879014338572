import React from "react";

const ValuationSummary = () => {
  return (
    <div className="overflow-x-auto rounded-radius-xl">
      <div className="min-w-full inline-block align-middle ">
        <div className="table-container">
          <table className="min-w-full dark:bg-primary-dark bg-white">
            <thead>
              <tr>
                <th className="pl-4 pr-3.5 pb-2 pt-4 text-lg-semibold bg-white text-blue-500 text-left">
                  Valuation Summary
                </th>
                <th className="px-3.5 pb-2 pt-4 text-xs-normal text-[#B5B5B5] text-left">
                  2023
                </th>
                <th className="px-3.5 pb-2 pt-4 text-xs-normal text-[#B5B5B5] text-left">
                  2024
                </th>
                <th className="px-3.5 pb-2 pt-4 text-xs-normal text-[#B5B5B5] text-left">
                  2025
                </th>
                <th className="px-3.5 pb-2 pt-4 text-xs-normal text-[#B5B5B5] text-left">
                  2026
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
                  EV / EBITDA:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  13.8x
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  8.8x
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  13.8x
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  13.8x
                </td>
              </tr>
              <tr>
              <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
              EV / EBIT:</td>
              <td className="px-3.5 text-xs-normal text-blue-900 text-left">
              16.7x
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  6.7x
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  1.7x
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  7.5x
                </td>
              </tr>
              <tr>
              <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
              P / E:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  120.0x
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  160.0x
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  180.0x
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  180.0x
                </td>
              </tr>
              <tr>
              <td className="pl-4 pr-3.5 py-2 text-sm-normal text-blue-900">
              FCF Yield:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  0.6%
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  7.6%
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  10.6%
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  10.9%
                </td>
              </tr>
              <tr>
              <td className="pl-4 pr-3.5 py-2 pb-4 text-sm-normal text-blue-900">
              Dividend yield:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  0.0%
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  0.0%
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  0.0%
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  0.0%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ValuationSummary;
