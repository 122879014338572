import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TextEditorExpandState {
  isAllowedToExpandWhatMatters: boolean;
  isAllowedToExpandCompanyInformation: boolean;
  isAllowedToExpandWhatMattersFundamental: boolean;
  isAllowedToExpandBussinesDescriptionFundamental: boolean;
  isAllowedToExpandCompanyHistoryFundamental: boolean;
  isAllowedToExpandCompanyBasicsFundamental: boolean;
  isAllowedToExpandDriversFundamental: boolean;
  isAllowedToExpandValuationHistoryFundamental: boolean;
  isAllowedToExpandProccesRegulatory: boolean;
  isAllowedToExpandWhatMattersFundamentalCreate: boolean;
  isAllowedToExpandBussinesDescriptionFundamentalCreate: boolean;
  isAllowedToExpandCompanyHistoryFundamentalCreate: boolean;
  isAllowedToExpandCompanyBasicsFundamentalCreate: boolean;
  isAllowedToExpandDriversFundamentalCreate: boolean;
  isAllowedToExpandValuationHistoryFundamentalCreate: boolean;
  isAllowedToExpandProccesRegulatoryCreate: boolean;
  isAllowedToExpandContentNotesCreate: boolean;
  isAllowedToExpandCommentaryNotesCreate: boolean;
}

const initialState: TextEditorExpandState = {
  isAllowedToExpandWhatMatters: false,
  isAllowedToExpandCompanyInformation: false,
  isAllowedToExpandWhatMattersFundamental: false,
  isAllowedToExpandBussinesDescriptionFundamental: false,
  isAllowedToExpandCompanyHistoryFundamental: false,
  isAllowedToExpandCompanyBasicsFundamental: false,
  isAllowedToExpandDriversFundamental: false,
  isAllowedToExpandValuationHistoryFundamental: false,
  isAllowedToExpandProccesRegulatory: false,
  isAllowedToExpandWhatMattersFundamentalCreate: false,
  isAllowedToExpandBussinesDescriptionFundamentalCreate: false,
  isAllowedToExpandCompanyHistoryFundamentalCreate: false,
  isAllowedToExpandCompanyBasicsFundamentalCreate: false,
  isAllowedToExpandDriversFundamentalCreate: false,
  isAllowedToExpandValuationHistoryFundamentalCreate: false,
  isAllowedToExpandProccesRegulatoryCreate: false,
  isAllowedToExpandContentNotesCreate: false,
  isAllowedToExpandCommentaryNotesCreate: false,
};

const textEditorExpandSlice = createSlice({
  name: "textEditorExpandSlice",
  initialState,
  reducers: {
    setIsallowedToExpandWhatMatters(state, action: PayloadAction<boolean>) {
      state.isAllowedToExpandWhatMatters = action.payload;
    },
    setIsAllowedToExpandCompanyInformation(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandCompanyInformation = action.payload;
    },
    setIsAllowedToExpandWhatMattersFundamental(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandWhatMattersFundamental = action.payload;
    },
    setIsAllowedToExpandBussinesDescriptionFundamental(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandBussinesDescriptionFundamental = action.payload;
    },
    setIsAllowedToExpandCompanyHistoryFundamental(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandCompanyHistoryFundamental = action.payload;
    },
    setIsAllowedToExpandCompanyBasicsFundamental(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandCompanyBasicsFundamental = action.payload;
    },
    setIsAllowedToExpandDriversFundamental(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandDriversFundamental = action.payload;
    },

    setIsAllowedToExpandValuationHistoryFundamental(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandValuationHistoryFundamental = action.payload;
    },
    setIsAllowedToExpandProccesRegulatory(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandProccesRegulatory = action.payload;
    },

    setIsAllowedToExpandWhatMattersFundamentalCreate(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandWhatMattersFundamentalCreate = action.payload;
    },
    setIsAllowedToExpandBussinesDescriptionFundamentalCreate(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandBussinesDescriptionFundamentalCreate =
        action.payload;
    },
    setIsAllowedToExpandCompanyHistoryFundamentalCreate(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandCompanyHistoryFundamentalCreate = action.payload;
    },
    setIsAllowedToExpandCompanyBasicsFundamentalCreate(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandCompanyBasicsFundamentalCreate = action.payload;
    },
    setIsAllowedToExpandDriversFundamentalCreate(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandDriversFundamentalCreate = action.payload;
    },

    setIsAllowedToExpandValuationHistoryFundamentalCreate(
      state,
      action: PayloadAction<boolean>
    ) {
      state.isAllowedToExpandValuationHistoryFundamentalCreate = action.payload;
    },
    setIsAllowedToExpandProccesRegulatoryCreate(state, action) {
      state.isAllowedToExpandProccesRegulatoryCreate = action.payload;
    },
    setIsAllowedToExpandContentNotesCreate(state, action) {
      state.isAllowedToExpandContentNotesCreate = action.payload;
    },
    setIsAllowedToExpandCommentaryNotesCreate(state, action) {
      state.isAllowedToExpandCommentaryNotesCreate = action.payload;
    },
  },
});

export const {
  setIsallowedToExpandWhatMatters: setIsallowedToExpandWhatMatters,
  setIsAllowedToExpandCompanyInformation:
    setIsAllowedToExpandCompanyInformation,
  setIsAllowedToExpandWhatMattersFundamental:
    setIsAllowedToExpandWhatMattersFundamental,
  setIsAllowedToExpandBussinesDescriptionFundamental:
    setIsAllowedToExpandBussinesDescriptionFundamental,
  setIsAllowedToExpandCompanyHistoryFundamental:
    setIsAllowedToExpandCompanyHistoryFundamental,
  setIsAllowedToExpandCompanyBasicsFundamental:
    setIsAllowedToExpandCompanyBasicsFundamental,
  setIsAllowedToExpandDriversFundamental:
    setIsAllowedToExpandDriversFundamental,
  setIsAllowedToExpandValuationHistoryFundamental:
    setIsAllowedToExpandValuationHistoryFundamental,
  setIsAllowedToExpandProccesRegulatory: setIsAllowedToExpandProccesRegulatory,

  setIsAllowedToExpandWhatMattersFundamentalCreate:
    setIsAllowedToExpandWhatMattersFundamentalCreate,
  setIsAllowedToExpandBussinesDescriptionFundamentalCreate:
    setIsAllowedToExpandBussinesDescriptionFundamentalCreate,
  setIsAllowedToExpandCompanyHistoryFundamentalCreate:
    setIsAllowedToExpandCompanyHistoryFundamentalCreate,
  setIsAllowedToExpandCompanyBasicsFundamentalCreate:
    setIsAllowedToExpandCompanyBasicsFundamentalCreate,
  setIsAllowedToExpandDriversFundamentalCreate:
    setIsAllowedToExpandDriversFundamentalCreate,
  setIsAllowedToExpandValuationHistoryFundamentalCreate:
    setIsAllowedToExpandValuationHistoryFundamentalCreate,
  setIsAllowedToExpandProccesRegulatoryCreate:
    setIsAllowedToExpandProccesRegulatoryCreate,
  setIsAllowedToExpandContentNotesCreate:
    setIsAllowedToExpandContentNotesCreate,
  setIsAllowedToExpandCommentaryNotesCreate:
    setIsAllowedToExpandCommentaryNotesCreate,
} = textEditorExpandSlice.actions;

export default textEditorExpandSlice.reducer;
