// React
import React, { useState } from "react";
// Third party
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
// OpenApi
import { Configuration, PasswordResetViewApi } from "../../openapi";
// Images
// import layer from "../../assets/images/layour.png";
import login from "../../assets/images/login.svg";
import { handleFormError } from "../../utils/errorHandling";

// Icons
import messageIcon from "../../assets/svg/message.svg";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is Required"),
});

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>("");
  const [success, setSuccess] = useState<string>("");

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        setErrors("");
        setSuccess("");

        const configuration = new Configuration({
          basePath: process.env.REACT_APP_API_URL,
        });

        const api = new PasswordResetViewApi(configuration);
        const response = await api.resetPassword(values.email);

        if (response.status === 200) {
          setSuccess(response.data);
        }
      } catch (error: any) {
        handleFormError(error);
      } finally {
        setLoading(false);
        resetForm();
      }
    },
  });

  return (
    <React.Fragment>
      <div className="flex h-screen relative -mt-[72px]">
        <div className="hidden md:block md:w-1/2  relative bg-[#DBECFF]">
          {/* <img
            src={layer}
            alt="Background"
            className="absolute h-screen w-full"
          /> */}
          <div className="flex justify-center items-center h-full px-[68px]">
            <img src={login} alt="Background" />
          </div>
        </div>

        <div className="w-full md:w-1/2 p-8 flex items-center justify-center bg-gray-100">
          <form
            className="w-full max-w-md"
            onSubmit={formik.handleSubmit}
            onChange={() => setErrors("")}
          >
            <h2 className="text-4xl-bold text-blue-900 uppercase   mb-14">
              One68 Global capital
            </h2>
            <h2 className="text-2xl-bold text-blue-900 mb-6">
              Forgot Password
            </h2>
            <div className="mb-[70px]">
              <label className="block text-base-normal text-blue-900 mb-2">
                Email Address
              </label>
              <div className="relative flex items-center">
                <input
                  name="email"
                  type="email"
                  className="placeholder:text-placeholder text-placeholder w-full rounded-radius-lg border-1 border-grey-300  bg-transparent py-[12px] px-4 pr-12  outline-none focus:border-primary focus-visible:shadow-none "
                  placeholder="Enter email here"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />

                <span className="absolute right-4">
                  <img src={messageIcon} alt="user" />
                </span>
              </div>
              {formik.errors.email && formik.touched.email ? (
                <div className="py-1 text-sm-normal text-danger">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
            {errors && (
              <div className="text-danger text-sm font-semibold text-center mb-3">
                {errors}
              </div>
            )}
            {success && (
              <div className="text-success text-sm font-semibold text-center mb-3">
                {success}
              </div>
            )}
            <div className="mb-[16px]">
              <button
                type="submit"
                className="w-full flex justify-center text-base-normal items-center text-[#fff] bg-secondary py-[12px] rounded-radius-lg hover:bg-blue-500 dark:bg-black"
                disabled={loading}
              >
                {loading && (
                  <svg
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="mr-2 animate-spin"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                  </svg>
                )}
                Send Password Reset Link
              </button>
            </div>
            <div className="text-center">
              <p className="text-sm-normal text-blue-900">
                Return to?{" "}
                <Link to="/" className="text-blue-500 ">
                  Log In
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
