import React from "react";

const Management = () => {
  return (
    <div className="table-container dark:bg-table-dark bg-table-light rounded-radius-xl">
      <table className="min-w-full">
        <caption className="text-left text-lg-semibold text-blue-500 pt-[16px] pb-2 px-3.5">
          Management
        </caption>
        <tbody>
          <tr>
            <td className="pl-4 pr-4 pb-2 text-sm-normal text-left">
              CFO:
            </td>
          </tr>
          <tr>
            <td className="pl-4 pr-4 pb-2 text-sm-normal text-left">
            CFO: 
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Management;
