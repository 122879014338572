// React
import React, { useCallback, useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
// OpenAPI
import { RegulatoryDomainApi } from "../../openapi";
import { getApiData } from "../../controller/getApiDataConfiguration";
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Components
import { apiConfig } from "../../components/ConfigurationApi/Configuration";
import ProcessTab from "./components/ProcessTab";
import CommentaryTab from "./components/CommentaryTab";
import { Columns } from "../../components/Columns/Columns";
import Loader from "../../components/Loader";
import RegulatoryInformation from "./components/RegulatoryInformation";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import RegulatoryNotes from "./components/RegulatoryNotes";
// Images
import RegulatoryIcon from "../../assets/svg/Regulatory.svg";
import Close from "../../assets/svg/close.svg";
// Types
import { HandleMenuProps, Sort } from "../../interfaces/pages/variedInterfaces";


const RegulatoryDetails: React.FC<HandleMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string | undefined>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isTextEditorExpanded, setTextEditorIsExpanded] = useState(false);

  useEffect(() => {
    fetchRegulatoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRegulatoryData = async () => {
    setLoading(true);
    try {
      const api = new RegulatoryDomainApi(apiConfig());
      const response = await api.getRegulatoryById(String(id));
      setData({ ...response?.data } || {});
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const fetchRegulatoryNoteList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      const getFetchRegulatoryNoteList = new getApiData();
      return getFetchRegulatoryNoteList.getNoteDomainApi2(
        String(id),
        pageIndex,
        pageSize,
        sortBy,
        "Regulatory"
      );
    },
    [id]
  );

  const tabsContent = [
    {
      header: "Process",
      content: (
        <>
          <ProcessTab data={data} fetchRegulatoryData={fetchRegulatoryData} isMenuOpen={isMenuOpen} isTextEditorExpanded={isTextEditorExpanded} setTextEditorIsExpanded={setTextEditorIsExpanded} />
        </>
      ),
    },
    {
      header: "Commentary",
      content: (
        <>
          <CommentaryTab
            data={data}
            fetchRegulatoryData={fetchRegulatoryData}
          />
        </>
      ),
    },
  ];

  const columnsInstance = new Columns();

  const openModal = (content: string) => {
    setContent(content);

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
            {isTextEditorExpanded ? (
        <div
          className={` ${
            isMenuOpen ? "left-[284px]" : "left-[60px]"
          } fixed   w-full h-full bg-slate-600 opacity-30  z-[998] duration-500`}
        ></div>
      ) : null}
      {loading && <Loader loading={loading} />}
      <div
        className={`py-4 pr-4 ${
          isMenuOpen ? "pl-[300px]" : "pl-[76px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["Regulatory", `${data?.name || ""}`]}
          icon={RegulatoryIcon}
          addItem={() => navigate("/regulatory/add")}
          updateItem={() => navigate(`/update-regulatory/${id}`)}
        />
        <div className=" pb-4  rounded-md ">
          <div className="flex flex-col md:flex-row gap-4 mt-4">
            <div className="md:w-1/2 flex flex-col">
              <div className=" overflow-y-visible">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-md overflow-visible bg-table-light pb-2">
                  <RegulatoryInformation data={data} fetchRegulatoryData={fetchRegulatoryData} />
                  </div>
                </div>
              </div>
            </div>

            <div className="md:w-1/2 ">
              <div className="flex gap-3 mb-2">
                {tabsContent.map((tab, index) => (
                  <button
                      data-testid={`tab-button-regulatory-${index}`}
                    key={`${index}btnn`}
                    className={`min-w-[50px] h-[36px] px-2 ${
                      activeTab === index &&
                      "bg-blue-500 text-white  text-xs-medium border-0"
                    } text-blue-500 border border-blue-primary text-xs-medium rounded-md`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.header}
                  </button>
                ))}
              </div>
              <div className="border border-[#E4E3ED] rounded-md bg-table-light dark:bg-table-dark  select-none" data-testid="tab-content">
                {tabsContent[activeTab].content}
              </div>
            </div>
          </div>
          <div className="overflow-x-auto mt-4">
            <div className="min-w-full inline-block align-middle">
            <RegulatoryNotes
                fetchRegulatoryNoteList={fetchRegulatoryNoteList}
                openModal={openModal}
                columnsInstance={columnsInstance}
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && content && (
        <div
          className={`fixed top-0 left-0 z-30 flex h-full min-h-screen w-full items-center justify-center px-4 py-5  ${
            isModalOpen ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <div
            className="absolute inset-0 transition-opacity bg-[#222222]"
            onClick={closeModal}
          ></div>
          <div className="pointer-events-auto relative flex h-[680px] w-[1440px] flex-col overflow-hidden rounded-md border-none bg-[#EAE5F7] bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
            <div className="flex justify-end p-5">
              <img
                src={Close}
                alt="close"
                onClick={() => closeModal()}
                className="rounded-full p-2 border border-[#E6E6F9] bg-b-seconday-light cursor-pointer"
              />
            </div>
            <h2 className="px-[50px] pt-[18px] pb-5 text-title  dark:bg-black font-semibold text-lg">
              Description
            </h2>
            {/*Modal body*/}
            <div className="relative overflow-y-auto py-4 px-[50px]">
              <ul className="list-disc">
                <li
                  className=" text-[#393C51]  dark:text-white  text-sm font-normal leading-5 capitalize mb-3"
                  dangerouslySetInnerHTML={{
                    __html: content || "",
                  }}
                />
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default RegulatoryDetails;
