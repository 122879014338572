// React
import React, { useEffect, useRef, useState } from "react";
// Third party
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
// Components
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loader from "../../components/Loader";
import NotesForm from "../../components/NotesForm";
import { CustomToast } from "./components/CustomToast";
import {showAlert} from "../../components/Modal/AlertModal";
// OpenAPI
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Helpers
import { convertImagesToBase64 } from "../../helpers/convertImageToBase64";
// Types
import { ErrorFileData } from "../../interfaces/pages/Errors";
import { HandleMenuProps } from "../../interfaces/pages/variedInterfaces";
// Services
import { createNoteApi, fetchNoteByIdApi, fetchNoteSourceTypeApi, updateNoteApi } from "./services/noteServices";
import MarkdownIt from 'markdown-it';

const initialValues = {
  title: "",
  noteSourceTypeId: "",
  sourceDetail: "",
  content: "",
  commentary: "",
  sentiment: "",
  datePublished: new Date(),
  author1: "",
  author2: "",
  author3: "",
  tags: [],
  files: { add: [], delete: [] },
  domains: [],
};

const NoteAddEdit: React.FC<HandleMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const [serverError, setServerError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [sourceType, setSourceType] = useState<any>([]);
  const [changedValues, setChangedValues] = useState<any>({});
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const [editorData, setEditorData] = useState({ ref: null, keyName: "" });
  const md = new MarkdownIt({
    html: true,
    linkify: true,
    breaks: true,
  });

  const processContent = async (content: any) => {
    const isMarkdown = !/<[a-z][\s\S]*>/i.test(content);

    let htmlContent;

    if (isMarkdown) {
      htmlContent = md.render(content);
    } else {
      htmlContent = content;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");

    const images = Array.from(doc.querySelectorAll("img"));

    for (const img of images) {
      const src = img.getAttribute("src");
      if (src) {
        try {
          const response = await fetch(src, { mode: "cors" });
          const blob = await response.blob();
          const base64: string = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });

          img.setAttribute("src", base64);
        } catch (error) {
          console.error(`Error converting image to Base64: ${error}`);
        }
      }
    }

    return doc.body.innerHTML;
  };


  // Fetch note source type options on component mount
  useEffect(() => {
    fetchNoteSourceType();
  }, []);

  // Function to fetch note source type options
  const fetchNoteSourceType = async () => {
    try {
      const response = await fetchNoteSourceTypeApi();
      setSourceType(response.data.content);
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchNoteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchNoteData = async () => {
    setLoading(true);
    try {
      const response = await fetchNoteByIdApi(String(id));

      if (response.data) {
        const data: any = response.data;
        data.datePublished = new Date(data.datePublished);

        data.tags =
          data.tags?.map((tag: any) => {
            return { value: tag.id, label: tag.tag };
          }) || null;
        data.domains = data.domains?.map((domain: any) => {
          return {
            label: `${domain.name} - ${domain.domainName}`,
            value: domain.id,
          };
        });
        delete data.mnaName;
        delete data.mnaSourceCategory;
        delete data.sourceType;
        data.old = data.files;

        const newData = {...data, content:md.render(await processContent((data.content)))}

        formik.setValues(newData);
        setChangedValues(data);
      }
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  // Formik hook for form management
  const formik: any = useFormik({
    initialValues,
    onSubmit: async (values: any, { setSubmitting, setErrors }) => {
      values[editorData.keyName] = editorData.ref;
      values.datePublished =
        formik.values["datePublished"] !== null
          ? new Date(values.datePublished).toISOString()
          : null;

      const content = values.content;

      const data: any = {
        ...formik.values,
        version: 0,
        sentiment: values.sentiment ? Number(values.sentiment) : null,
        noteSourceTypeId: values.noteSourceTypeId
            ? Number(values.noteSourceTypeId)
            : null,
        datePublished: values?.datePublished,
        content,
      };

      if (data.tags?.length > 0) {

        const tags = data.tags.reduce(
          (result: any, obj: any) => {
            if (!changedValues.tags?.includes(obj)) {
              result.add = result.add || [];
              result.add = [...result.add, { tag: obj.label }];
            }
            return result;
          },
          { add: null, delete: null }
        );

        changedValues.tags?.forEach((item: any) => {
          const deletedObj = data.tags?.map((obj: any) => {
            if (obj?.value === item.value) {
              return obj.value;
            } else {
              return null;
            }
          });
          if (!deletedObj.includes(item.value)) {
            tags.delete = tags.delete || [];
            tags.delete = [...tags.delete, item.value];
          }
        });

        if (id) {
          data.tags = tags;
        } else {
          delete tags.delete;
          data.tags = tags?.add?.length > 0 ? tags : null;
        }

      } else {
        const tags: any = { add: null, delete: [] };
        tags.delete = changedValues.tags?.map((item: any) => item.value);
        data.tags = id ? tags : null;
      }

      if (data.domains?.length > 0) {

        const domains = data.domains.reduce(
          (result: any, obj: any) => {
            if (!changedValues.domains?.includes(obj)) {
              result.add = result.add || [];
              result.add = [
                ...result.add,
                { entityId: obj.value, typeId: obj.domainTypeId },
              ];
            }
            return result;
          },
          { add: null, delete: null }
        );

        changedValues.domains?.forEach((item: any) => {
          const deletedObj = data.domains?.map((obj: any) => {
            if (obj?.value === item.value) {
              return obj.value;
            } else {
              return null;
            }
          });

          if (!deletedObj.includes(item.value)) {
            domains.delete = domains.delete || [];
            domains.delete = [...domains.delete, item.value];
          }
        });

        if (id) {
          data.domains = domains;
        } else {
          delete domains.delete;
          data.domains = domains?.add?.length > 0 ? domains : null;
        }

      } else {
        const domains: any = { add: null, delete: [] };
        domains.delete = changedValues.domains?.map((item: any) => item.id);
        data.domains = id ? domains : null;
      }

      if (
        Object.keys(data.files).includes("add") ||
        Object.keys(data.files).includes("delete")
      ) {
      } else {
        data.files = { add: null, delete: null };
      }

      delete data.old;
      if (submitButtonRef.current && !submitButtonRef.current.disabled) {
        submitButtonRef.current.disabled = true;
        try {
          if (id) {
            // Update API call
            await updateNoteApi(String(id), data);
            navigate(`/notes/${id}`);
            showAlert({
              message: 'Note updated successfully!',
              type: 'success'
            })
          } else {
            // Creat Note API call
            const response = await createNoteApi(data);
            const newNoteId = response?.data;
            showAlert({
              message: 'Note created successfully!',
              type: 'success'
            })
            if (newNoteId) {
              navigate(`/notes/${newNoteId}`);
            }
          }
        } catch (error: any) {
          if (error.response) {
            values.datePublished =
              formik.values["datePublished"] !== null
                ? new Date(values.datePublished)
                : null;
            if (
              error.response.data?.exception &&
              !error.response.data?.fieldErrors?.length
            ) {
              CustomToast(error.response.data?.exception || "An error occurred", "error");
            }
            if (error.response.data?.fieldErrors?.length) {
              const errorsObject = {} as any;
              error.response.data?.fieldErrors.forEach(
                (error: ErrorFileData) => {
                  errorsObject[error.field] = error.defaultMsg;
                }
              );
              setErrors(errorsObject);
              setServerError(errorsObject);
              showAlert({
                message: 'Failed to create new Note!',
                type: 'error'
              });
            }
          } else {
            // Handle other errors
            console.error("Error submitting form:", error.message);
          }
        } finally {
          setSubmitting(false);
          if (submitButtonRef.current) {
            submitButtonRef.current.disabled = false;
          }
        }
      }
    },
  });

  const handleEditorRef = (ref: any) => {
    setEditorData(ref);
  };

  return (
    <React.Fragment>
      <div
        className={`py-[2rem] pr-[2rem] ${isMenuOpen ? "pl-[316px]" : "pl-[92px]"
          } duration-500`}
      >
        {" "}
        {/* Breadcrumbs component */}
        <div className="-ml-4">
        <Breadcrumbs breadcrumbs={[id ? "Update Note" : "Add New Note"]} />
        </div>
        <div>
          {/* Form */}
          <form
            onSubmit={formik.handleSubmit}
            onChange={() => setServerError(null)}
          >
            <NotesForm
              formik={formik}
              serverError={serverError}
              sourceType={sourceType}
              onEditorRef={handleEditorRef}
              isMenuOpen={isMenuOpen}
            />
            <div className="flex justify-end gap-[30px] mt-20">
              <div>
                <button
                  type="button"
                  className="py-[18px] border border-secondary text-secondary dark:bg-button-dark dark:text-white text-base font-medium rounded-full px-[116px]"
                  onClick={() => navigate("/notes")}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  type="button"
                  ref={submitButtonRef}
                  onClick={formik.handleSubmit}
                  name="Save"
                  className="py-[18px] border border-secondary text-[#fff] bg-primary-default text-base font-medium rounded-full px-[103px]"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </React.Fragment>
  );
};

export default NoteAddEdit;
