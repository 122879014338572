// React
import React, { useEffect, useState } from "react";
// Components
import EditableFieldMna from "../../../components/EditableFields/EditableField";
// Types
import { Item } from "../../../interfaces/pages/variedInterfaces";
// Images
import Expand from "./../../../assets/svg/expand.svg";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setIsallowedToExpandWhatMatters } from "../../../features/textEditorExpand";

interface WhatMattersTabProps {
  data: any;
  fetchMnaData: () => void;
  isMenuOpen?: boolean;
  isTextEditorExpanded: boolean;
  setTextEditorIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const WhatMattersTab: React.FC<WhatMattersTabProps> = ({
  data,
  fetchMnaData,
  isMenuOpen,
  isTextEditorExpanded,
  setTextEditorIsExpanded,
}) => {
  const dispatch = useDispatch();
  const isAllowedToExpandWhatMatters = useSelector(
    (state: RootState) => state.textEditorExpand.isAllowedToExpandWhatMatters
  );

  useEffect(() => {
    return () => {
      dispatch(setIsallowedToExpandWhatMatters(false));
    };
  }, [dispatch]);
  
  const handleFetchMnaData = async () => {
    fetchMnaData();
  };
  return (
    <div className={``}>
      <div
        className={`${
          isTextEditorExpanded
            ? `fixed bg-white top-[92px] right-[16px] rounded-radius-lg duration-500 transition-[width] ${
                isMenuOpen ? "w-[calc(100%-316px)] " : "w-[calc(100%-92px)]"
              }   z-[999] `
            : ""
        }`}
      >
        <div
          data-test-id="tab-content-mna"
          className="px-4 pt-4 dark:bg-black text-lg-medium text-blue-500 dark:text-white text-lg  flex items-center justify-between rounded-tl-md rounded-tr-md"
        >
          <span>What Matters</span>
          {isAllowedToExpandWhatMatters ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                setTextEditorIsExpanded((prev: boolean) => !prev);
              }}
            >
              <img src={Expand} alt="expand" />
            </div>
          ) : null}
        </div>
        <div className="bg-table-light dark:bg-table-dark rounded-bl-md rounded-br-md overflow-hidden ">
          <div className={`w-full px-2 ${isTextEditorExpanded ? "" : "pb-2"}`}>
            {data?.whatMatters?.length > 0 ? (
              data.whatMatters.map(
                (item: Item, index: number) =>
                  item && (
                    <div key={item.id} className="">
                      {data.whatMatters.length > 1 && (
                        <p>
                          <span className="text-xs-medium ml-2">
                            Created by {item?.createdBy?.username} :
                          </span>
                        </p>
                      )}
                      <EditableFieldMna
                        data={item.note}
                        fieldtype="textEditor"
                        fieldName="whatMatters"
                        fetchMnaData={handleFetchMnaData}
                        dataResponse={data}
                        whatMatterId={item.id}
                        noteStatus={"update"}
                        setTextEditorIsExpanded={setTextEditorIsExpanded}
                        isTextEditorExpanded={isTextEditorExpanded}
                        editorFor="mna"
                      />
                    </div>
                  )
              )
            ) : (
              <div>
                <EditableFieldMna
                  data=""
                  fieldtype="textEditor"
                  fieldName="whatMatters"
                  fetchMnaData={handleFetchMnaData}
                  dataResponse={data}
                  whatMatterId={null}
                  noteStatus={"newNote"}
                  setTextEditorIsExpanded={setTextEditorIsExpanded}
                  isTextEditorExpanded={isTextEditorExpanded}
                  editorFor="mna"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatMattersTab;
