// React
import React from "react";
// Images
import Arrow from "../../../../src/assets/svg/arrow-down-select.svg";

interface SectorSelectProps {
  id: string;
  name: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  onBlur: React.FocusEventHandler<HTMLSelectElement>;
  sectorList: Array<{ value: string; label: string }>;
}

const SectorSelectMna: React.FC<SectorSelectProps> = ({
  id,
  name,
  value,
  onChange,
  onBlur,
  sectorList,
}) => {
  return (
    <select
      id={id}
      name={name}
      className={`dark:text-white dark:placeholder:text-white dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12 outline-none`}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={{
        appearance: "none",
        backgroundColor: "white",
        color: "#667185",
        borderRadius: "0.375rem",
        paddingLeft: "15px",
        paddingRight: "35px",
        fontSize: "1rem",
        fontWeight: 300,
        width: "100%",
        height: "3rem",
        outline: "none",
        transition: "border-color 0.3s ease-in-out",
        backgroundImage: `url(${Arrow})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "calc(100% - 20px) center",
        backgroundSize: "16px 16px",
      }}
    >
      <option value="">Sector</option>
      {sectorList.map((item, index) => (
        <option key={index} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
};

export default SectorSelectMna;
