// React
import React from "react";

export default function TopFiveHolders() {
  return (
    <div className="border-1 border-gray-200 rounded-radius-md overflow-hidden">
      <table className="min-w-full dark:bg-table-dark bg-table-light ">
        <caption className="text-left text-blue-500 text-lg-medium pt-4 pb-2 px-4 bg-white dark:bg-black">
          Holders Top 5 Holders
        </caption>
        <tbody >
          <tr className="flex justify-between items-center w-full mb-[8px]">
            <td className="px-4  dark:text-white text-xs-normal text-blue-900 text-left">
              8.30%
            </td>
            <td className="px-3.5 text-xs-normal text-blue-900 text-left">
              Vanguard
            </td>
            <td className="px-3.5 text-xs-normal text-blue-900 text-left">
              ATVI
            </td>
            <td className="px-4 dark:text-white text-xs-normal text-blue-900 flex">
              <div> Changed: James</div>
              <div className="ml-2">November 11, 2023</div>
            </td>
          </tr>
          <tr className="flex justify-between items-center w-full mb-[8px]">
            <td className="px-4  dark:text-white text-xs-normal text-blue-900 text-left">
              8.30%
            </td>
            <td className="px-3.5 text-xs-normal text-blue-900 text-left">
              Vanguard
            </td>
            <td className="px-3.5 text-xs-normal text-blue-900 text-left">
              ATVI
            </td>
            <td className="px-4 dark:text-white text-xs-normal text-blue-900 flex">
              <div> Changed: James</div>
              <div className="ml-2">November 11, 2023</div>
            </td>
          </tr>
          <tr className="flex justify-between items-center w-full mb-[8px]">
            <td className="px-4  dark:text-white text-xs-normal text-blue-900 text-left">
              8.30%
            </td>
            <td className="px-3.5 text-xs-normal text-blue-900 text-left">
              Vanguard
            </td>
            <td className="px-3.5 text-xs-normal text-blue-900 text-left">
              ATVI
            </td>
            <td className="px-4 dark:text-white text-xs-normal text-blue-900 flex">
              <div> Changed: James</div>
              <div className="ml-2">November 11, 2023</div>
            </td>
          </tr>
          <tr className="flex justify-between items-center w-full mb-[8px]">
            <td className="px-4  dark:text-white text-xs-normal text-blue-900 text-left">
              8.30%
            </td>
            <td className="px-3.5 text-xs-normal text-blue-900 text-left">
              Vanguard
            </td>
            <td className="px-3.5 text-xs-normal text-blue-900 text-left">
              ATVI
            </td>
            <td className="px-4 dark:text-white text-xs-normal text-blue-900 flex">
              <div> Changed: James</div>
              <div className="ml-2">November 11, 2023</div>
            </td>
          </tr>
          <tr className="flex justify-between items-center w-full mb-4">
            <td className="px-4  dark:text-white text-xs-normal text-blue-900 text-left">
              8.30%
            </td>
            <td className="px-3.5 text-xs-normal text-blue-900 text-left">
              Vanguard
            </td>
            <td className="px-3.5 text-xs-normal text-blue-900 text-left">
              ATVI
            </td>
            <td className="px-4 dark:text-white text-xs-normal text-blue-900 flex">
              <div> Changed: James</div>
              <div className="ml-2">November 11, 2023</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
