// React
import React from "react";
// Third party
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";

interface UpcomingEvent {
  dateEventModifier: string;
  eventTypeName: string;
  targetId: string | number;
  targetName: string;
  mnaName: string;
  mnaId: string;
  dateEventTypeId: string;
  dateEventTypeName: string;
  comment?: string;
}

interface UpcomingEventsResponse {
  content: UpcomingEvent[];
}

interface UpcomingEventsTableProps {
  upcomingEvents: UpcomingEventsResponse;
  handleMouseEnter: any;
  handleMouseLeave: any;
}

const UpcomingEventsTable: React.FC<UpcomingEventsTableProps> = ({
  upcomingEvents,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className=" min-w-full inline-block align-middle">
        <div className="shadow-shadow-md  overflow-hidden rounded-radius-lg">
          <table className="min-w-full dark:bg-table-dark bg-table-light">
            <caption className="text-left text-lg-semibold dark:bg-black bg-white text-blue-500 p-squish-extra-sm">
              Upcoming Events
            </caption>
            <thead className="bg-grey-50 dark:bg-black dark:text-white">
              <tr>
                <th
                  scope="col"
                  className="p-squish-extra-sm text-start text-blue-900 text-sm-semibold w-1/3"
                >
                  MNA Name
                </th>
                <th
                  scope="col"
                  className="p-squish-extra-sm text-start text-blue-900 text-sm-semibold w-1/3"
                >
                  Date Event Type
                </th>
                <th
                  scope="col"
                  className="p-squish-extra-sm text-start text-blue-900 text-sm-semibold w-1/3"
                >
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="divide-y align-top">
              {upcomingEvents.content?.map(
                (upcoming: UpcomingEvent, index: number) => (
                  <tr key={index} className=" border-b-1 border-b-grey-200">
                    <td
                      className="p-squish-extra-sm text-sm-normal"
                      onClick={() => navigate(`/mna/${upcoming?.targetId}`)}
                    >
                      <Link
                        className="text-secondary"
                        to={`/mna/${upcoming?.targetId}`}
                      >
                        {" "}
                        {upcoming.targetName}
                      </Link>
                    </td>
                    <td
                      className="p-squish-extra-sm text-sm-normal"
                      onMouseEnter={(e) =>
                        handleMouseEnter(upcoming, {
                          x: e.clientX,
                          y: e.clientY,
                        })
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      {upcoming.eventTypeName}
                    </td>

                    <td
                      className="p-squish-extra-sm text-sm-normal break-words "
                      onMouseEnter={(e) =>
                        handleMouseEnter(upcoming, {
                          x: e.clientX,
                          y: e.clientY,
                        })
                      }
                      onMouseLeave={handleMouseLeave}
                    >
                      {`${moment(
                        upcoming.dateEventModifier.split(" ")[0]
                      ).format("MM/DD/YYYY")} ${upcoming.dateEventModifier
                        .split(" ")
                        .slice(1)
                        .join(" ")}`}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UpcomingEventsTable;
