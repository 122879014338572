import React from "react";

const PriceTarget = () => {
  return (
    <div className="overflow-x-auto rounded-radius-xl">
      <div className="min-w-full inline-block align-middle">
        <div className="table-container">
          <table className="min-w-full dark:bg-primary-dark bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 pt-4  text-left text-blue-500 text-lg-semibold">
                  Price Target
                </th>
                <th className="px-4 py-2 pt-4  text-xs-normal text-[#B5B5B5] text-left ">
                  Downside
                </th>
                <th className="px-4 py-2 pt-4  text-xs-normal text-[#B5B5B5] text-left ">
                  Base
                </th>
                <th className="px-4 py-2 pt-4  text-xs-normal text-[#B5B5B5] text-left ">
                  Upside
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 py-2 text-sm-normal text-blue-900 ">
                  Simple Target:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $160.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-sm-normal text-blue-900 ">
                  Dividend/Other:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  0.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  0.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  0.00
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-sm-normal text-blue-900 ">
                  Total Return:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $160.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-sm-normal text-blue-900 ">
                  Nominal % Return:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  -10.00%
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  -16.00%
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  -180.00%
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-sm-normal text-blue-900 ">Probability:</td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
                <td className="px-3.5 text-[#000817] dark:text-white text-base font-medium text-left"></td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-sm-normal text-blue-900 ">
                  Risk/Reward:
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $120.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $160.00
                </td>
                <td className="px-3.5 text-xs-normal text-blue-900 text-left">
                  $180.00
                </td>
              </tr>
              <tr>
                <td className="px-4 pt-2  pb-4 text-sm-normal text-blue-900 ">
                  Annualized Return:
                </td>
                <td className="px-3.5  pb-2 text-xs-normal text-blue-900 text-left">
                  $120.00
                </td>
                <td className="px-3.5 pb-2 text-xs-normal text-blue-900 text-left">
                  $160.00
                </td>
                <td className="px-3.5  pb-2 text-xs-normal text-blue-900 text-left">
                  $180.00
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PriceTarget;
