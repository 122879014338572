import React from "react";

interface CancelButtonProps {
  onClick: () => void;
}

const CancelButtonMna: React.FC<CancelButtonProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className=" rounded-radius-lg py-[12px] px-[18px] border-1 border-gray-300 flex items-center justify-center  text-sm-medium"
    >
      Cancel
    </button>
  );
};

export default CancelButtonMna;
