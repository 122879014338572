//React
import { useState } from "react";

//3rd party
import ReactDatePicker from "react-datepicker";

//ICONS
import Arrowleft from "../../assets/svg/arrow-left.svg";
import ArrowRight from "../../assets/svg/arrow-right.svg";

interface CustomDatePickerProps {
  selected: Date | null;
  handleDateChage: (date: Date | null, event?: React.SyntheticEvent) => void;
  name: string;
  id: string;
  setNewCondition: React.Dispatch<
    React.SetStateAction<{
      version: number;
      mnaId: string | undefined;
      conditionCategoryId: number;
      categoryOtherComment: string;
      statusId: number;
      filed: null;
      expectedConfirmed: null;
      commentary: string;
      expectedConfirmedFlag: boolean;
    }>
  >;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  selected,
  handleDateChage,
  name,
  id,
  setNewCondition,
}) => {
  const [isCalendarFieldOpen, setCalendarFieldOpen] = useState(false);

  return (
    <>
      <ReactDatePicker
        autoComplete="off"
        selected={selected}
        onChange={handleDateChage}
        name={name}
        id={id}
        className="w-full  mt-[6px] px-2 py-1  border-1 border-blue-primary rounded-[4px] focus:outline-none text-xs-normal"
        dateFormat="MM/dd/yyyy"
        showMonthDropdown
        showYearDropdown
        showPopperArrow={false}
        shouldCloseOnSelect={false}
        dropdownMode="select"
        open={isCalendarFieldOpen}
        onClickOutside={() => setCalendarFieldOpen(false)}
        onFocus={() => setCalendarFieldOpen(true)}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: 10,
              alignItems: "center",
              color: "#16234A",
              fontWeight: 700,
            }}
          >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <img src={Arrowleft} />
            </button>
            <span>
              {date.toLocaleString("en-US", { month: "long", year: "numeric" })}
            </span>
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <img src={ArrowRight} />
            </button>
          </div>
        )}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "16px",
            padding: "1px",
            textAlign: "center",
          }}
        >
          <button
            className="w-1/2 bg-blue-primary rounded-lg py-2 text-white"
            onClick={() => setCalendarFieldOpen(false)}
          >
            Ok
          </button>
          <button
            className="w-1/2 bg-white rounded-lg border-1 py-2"
            onClick={() => {
              setNewCondition((prev) => ({
                ...prev,
                filed: null,
              }));
              setCalendarFieldOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </ReactDatePicker>
    </>
  );
};

export default CustomDatePicker;
