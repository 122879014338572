// React
import React, { useCallback, useState } from "react";
// Components
import { Columns } from "../../components/Columns/Columns";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Table from "../Fundamental/components/MnaFundamentalTable";
// OpenAPI
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Types
import {
  Sort,
  HandleMenuProps,
} from "../../interfaces/pages/variedInterfaces";
// Images
import fileIcon from "../../assets/svg/blue-file.svg";
// Services
import { downloadFilesApi, fetchFiles } from "./services/fileServices";

const FileList: React.FC<HandleMenuProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const [search] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadingFileId, setDownloadingFileId] = useState<string | null>(null);


  const fetchMnaList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      setLoading(true);
      try {

        const response = await fetchFiles(search, pageIndex, pageSize, sortBy);
        return response.data;

      } catch (error) {
        expiredTokenValidation(error);
      } finally {
        setLoading(false);
      }
    },
    [search]
  );

  const handleFileDownload = useCallback(
    async (id: string, type: string) => {
      setDownloadingFileId(id);
      try {
        await downloadFilesApi(id, type);
      } catch (error) {
        console.error("Error downloading file:", error);
      } finally {
        setDownloadingFileId(null);
      }
    },
    []
  );

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
        <div
         className={`py-4  flex flex-col bg-white ${
          isMenuOpen ? "pl-[282px]" : "pl-[60px]"
        } duration-500`}
      >
        <div className="ml-4">
        <Breadcrumbs breadcrumbs={["Files"]} icon={fileIcon} />
        </div>
        <div className="mt-[10px]">
          <Table
            isMenuOpen={isMenuOpen}
            columns={columnsInstance.FileList(handleFileDownload, downloadingFileId)}
            fetchData={fetchMnaList}
            search={search}
            loading={loading}
            typeForPagination="setFilesListPageIndex"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default FileList;
