// React
import React, { useEffect } from "react";
// Third party
import { useFormik } from "formik";
import * as Yup from "yup";
// OpenAPI
import { ContactDomainApi, ContactRequest } from "../../../openapi";
// Components
import { apiConfig } from "../../../components/ConfigurationApi/Configuration";
import DeleteContactModal from "../../../components/DeleteModal/DeleteContactModal/DeleteContactModal";
import EditableFieldMna from "../../../components/EditableFields/EditableField";
// Images
import DeleteIcon from "../../../assets/svg/bin.svg"; 
import SubmitIcon from "../../../assets/images/checkmark.png";
import CancelIcon from "../../../assets/svg/close.svg";
import AddContactIcon from "../../../assets/svg/addContactIcon.svg";


interface ContactsProps {
  data: any;
  fetchMnaData: () => void;
  setData: React.Dispatch<React.SetStateAction<any>>;
  setFetchRequired: React.Dispatch<React.SetStateAction<boolean>>;
  isContactDeleteModalOpen: boolean;
  setIsContactDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteContactId: React.Dispatch<React.SetStateAction<string>>;
  onDeleteContact: () => void;
  isContactAdd: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsContactAdd: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Contacts({
  data,
  fetchMnaData,
  setData,
  setFetchRequired,
  isContactDeleteModalOpen,
  setIsContactDeleteModalOpen,
  setDeleteContactId,
  onDeleteContact,
  isContactAdd,
  setLoading,
  setIsContactAdd,
}: ContactsProps) {
  const handleFetchMnaData = async () => {
    await fetchMnaData();
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const addContactRequestBody: ContactRequest = {
        name: values?.name,
        phone: values?.phone,
        email: values?.email,
        domain: {
          typeId: 1,
          entityId: String(data?.id),
        },
      };

      try {
        setLoading(true);
        const api = new ContactDomainApi(apiConfig());
        await api.createContact(addContactRequestBody);
        setIsContactAdd(false);
        formik.resetForm();
        // Trigger fetch to get updated data after adding contact
        setFetchRequired((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    handleFetchMnaData();
  }, [setFetchRequired]); // Fetch data when fetchRequired changes

  return (
    <div className="w-full">
      <div className="flex flex-col justify-between border-1 border-gray-200 rounded-radius-md p-4 relative min-h-[145px] bg-white dark:bg-black overflow-hidden">
        <table className="table-auto w-full rounded-bl-lg rounded-br-lg">
          <caption className="text-left text-blue-500 text-lg-medium pb-[14px] bg-table-light dark:bg-table-dark">
            Contacts
          </caption>
          <thead className="">
            <tr>
              <th className="text-left  text-[#000000] text-sm-normal w-[30.5%]">Name</th>
              <th className="text-left  text-[#000000] text-sm-normal w-[30.5%]">Phone</th>
              <th className="text-left text-[#000000] text-sm-normal w-[30.5%]">Email</th>
              <th className="text-left text-[#000000] text-sm-normal"></th>
            </tr>
          </thead>
          <tbody  >
            {
            // data?.contacts?.length > 0 ? (
              
              data?.contacts?.map((details: any) => (
                <tr key={details.id} >
                  <td className="w-[30.5%] h-full">
                    <div className="-ml-2 mr-4">
                    <EditableFieldMna
                      fieldtype="text"
                      fieldName="name"
                      data={details?.name}
                      fullData={data}
                      dateIndex={""}
                      setFetchMnaDetails={() =>
                        setFetchRequired((prev) => !prev)
                      }
                      setLocalData={setData}
                      fetchMnaData={handleFetchMnaData}
                      isContactEdit={true}
                      contactData={details}
                    />
                    </div>
                  </td>
                  <td className="w-[30.5%] h-full">
                  <div className="-ml-2 mr-4">
                    <EditableFieldMna
                      fieldtype="text"
                      fieldName="phone"
                      data={details?.phone}
                      fullData={data}
                      dateIndex={""}
                      setFetchMnaDetails={() =>
                        setFetchRequired((prev) => !prev)
                      }
                      setLocalData={setData}
                      fetchMnaData={handleFetchMnaData}
                      isContactEdit={true}
                      contactData={details}
                    />
                    </div>
                  </td>
                  <td className="w-[30.5%] h-full">
                  <div className="-ml-2">

                    <EditableFieldMna
                      fieldtype="text"
                      fieldName="email"
                      data={details?.email}
                      fullData={data}
                      dateIndex={""}
                      setFetchMnaDetails={() =>
                        setFetchRequired((prev) => !prev)
                      }
                      setLocalData={setData}
                      fetchMnaData={handleFetchMnaData}
                      isContactEdit={true}
                      contactData={details}
                    />
                    </div>
                  </td>
                  <td className="flex justify-end h-full pt-[5px]">
                    <div className="bg-gray-200 p-[5px] rounded-radius-full">
                    <img
                      src={DeleteIcon}
                      alt="delete"
                      className="h-full w-[15px] object-contain cursor-pointer"
                      onClick={() => {
                        setIsContactDeleteModalOpen(true);
                        setDeleteContactId(details?.id);
                      }}
                    />
                    </div>
                    <DeleteContactModal
                      isDeleteModalOpen={isContactDeleteModalOpen}
                      setIsDeleteModalOpen={setIsContactDeleteModalOpen}
                      onDeleteItem={() => {
                        onDeleteContact();
                        setFetchRequired((prev) => !prev); // Trigger data fetch after deleting contact
                      }}
                    />
                  </td>
                </tr>
              ))
            // ) : (
            //   <tr className="text-center">
            //     {/* <td
            //       colSpan={4}
            //       className="text-sm-semibold p-4"
            //     >
            //       No data found
            //     </td> */}
            //   </tr>
            // )
            }

            {isContactAdd && (
              <tr className="space-x-4">
                <td className="w-[30.5%] h-full pr-1">
                  <div className="relative"> 
                  <input
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mt-2 border-1 border-blue-500 w-full text-sm-normal rounded-sm pl-2 pr-2 placeholder-text-14"
                    placeholder="Enter name"
                  />

                  {formik.touched.name && formik.errors.name && (
                    <div className="text-danger text-xs-normal absolute" >
                      {formik.errors.name}
                    </div>
                  )}
                  </div>
                </td>
                <td className="w-[30.5%] h-full pr-1">
                  <div className="relative">
                  <input
                    type="text"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mt-2 border-1 border-blue-500 w-full text-sm-normal rounded-sm pl-2 pr-2 placeholder-text-14"
                    placeholder="Enter phone"
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className="text-danger text-xs-normal text-sm absolute">
                      {formik.errors.phone}
                    </div>
                  )}
                  </div>
                </td>

                <td className="w-[30.5%] h-full pr-1 ">
                  <div className="relative">
                  <input
                    type="text"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mt-2 border-1 border-blue-500 w-full text-sm-normal rounded-sm pl-2 pr-2 placeholder-text-14" 
                    placeholder="Enter email"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-danger text-xs-normal absolute">
                      {formik.errors.email}
                    </div>
                  )}
                  <div className="flex justify-center bottom-[-20px] right-0 absolute">
                    <div className="border-1 border-grey-50  bg-white shadow-shadow-md mr-1 rounded-sm">
                  <img
                    src={SubmitIcon}
                    alt="Submit Icon"
                    className="h-full w-[15px] object-contain cursor-pointer "
                    onClick={() => formik.handleSubmit()}
                  />
                  </div>
                  <div className=" border-1 border-grey-50  bg-white shadow-shadow-md rounded-sm">

                  <img
                    src={CancelIcon}
                    alt="Cancel Icon"
                    className="h-full w-[15px] object-contain cursor-pointer"
                    onClick={() => setIsContactAdd(false)}
                  />
                  </div>
                </div>
                  </div>
                </td>

                
              </tr>
            )}
          </tbody>
        </table>

        <div className="relative bottom-0 mt-4 min-h-[30px] flex justify-center items-center">
        <div className="text-right flex justify-end absolute ">
          <img
            src={AddContactIcon}
            alt="Add Contact"
            className="cursor-pointer text-end"
            onClick={() => {
              setIsContactAdd(true);
              formik.resetForm();
            }}
          />
        </div>
        </div>
      </div>
    </div>
  );
}
