// React
import React from 'react';
// Third party
import {Link} from "react-router-dom";
import moment from "moment/moment";
// Types
import {Note} from "../../../interfaces/pages/variedInterfaces";

interface FirstListGroupProps {
    data: any
}

const FirstListGroup: React.FC<FirstListGroupProps> = ({data}) => {
    return (
        <div>
            <div className=" min-w-full inline-block align-middle  ">
                <div className="shadow-shadow-md  overflow-hidden rounded-radius-lg">
                    <table className="min-w-full dark:bg-black bg-white">
                        <caption
                            className="text-left text-lg-semibold dark:bg-black bg-white text-blue-500 p-squish-extra-sm">
                            Notes - Recently Modified
                        </caption>
                        <thead 
                        className="bg-grey-50 dark:bg-black dark:text-white">
                        <tr className=' border-b-1 border-b-grey-200'>
                            <th
                                scope="col"
                                className="p-squish-extra-sm text-start text-blue-900 text-sm-semibold"
                            >
                                Title
                            </th>
                            <th
                                scope="col"
                                className="p-squish-extra-sm text-start text-blue-900 text-sm-semibold w-[130px]"
                            >
                                Source Type
                            </th>
                            <th
                                scope="col"
                                className="p-squish-extra-sm text-start text-blue-900 text-sm-semibold"
                            >
                                Date Modified
                            </th>
                       
                        </tr>
                        </thead>
                        
                        <tbody className="divide-y align-top">
                        {data.notes?.map((note: Note, index: number) => (
                            <tr key={index} className=' border-b-1 border-b-grey-200'>
                                <td className="p-squish-extra-sm text-sm-normal break-words ">
                                    <Link
                                        className="text-secondary"
                                        to={`/notes/${note?.id}`}
                                    >
                                        {note.title}
                                    </Link>
                                </td>
                                     <td
                                    scope="row"
                                    className="p-squish-extra-sm text-sm-normal"
                                >
                                    {note?.sourceType}
                                </td>
                                <td
                                 className="p-squish-extra-sm text-sm-normal">
                                    {moment(note.dateModified).format(
                                        "MM/DD/YYYY hh:mm A"
                                    )}
                                </td>
                           
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default FirstListGroup;
