// React
import React, { useEffect, useRef, useState } from "react";
// Components
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loader from "../../components/Loader";
import FirstListGroup from "./components/FirstListGroup";
import SecondListGroup from "./components/SecondListGroup";
import UpcomingEventsTable from "./components/UpcomingEventsTable";
// Types
import {
  Upcoming,
  HandleMenuProps,
} from "../../interfaces/pages/variedInterfaces";
// Images
import dashboardIcon from "../../assets/svg/blue-dashboard.svg";
// Services
import { dashboardServices } from "./services/dashboardServices";

const Dashboard: React.FC<HandleMenuProps> = ({ isMenuOpen }) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [upcomingEvents, setUpcomingEvents] = useState<any>([]);
  const [hoveredEvent, setHoveredEvent] = useState<Upcoming | null>(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [dropdownOpen, setDropdownOpen] = useState<string>("");
  const dropdown = useRef<any>();

  useEffect(() => {
    fetchDashboardList();
    fetchUpcomingEvents();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = ({ target }: MouseEvent) => {
    if (dropdown.current && !dropdown.current.contains(target as Node)) {
      setDropdownOpen("");
    }
  };

  const fetchDashboardList = async () => {
    setLoading(true);
    try {
      const dashboardData = await dashboardServices.dashboardList();
      setData(dashboardData);
    } catch (error) {
      console.error("Error fetching dashboard data", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUpcomingEvents = async () => {
    setLoading(true);
    try {
      const upcomingData = await dashboardServices.upcomingDateEvents();
      setUpcomingEvents(upcomingData);
    } catch (error) {
      console.error("Error fetching upcoming events", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMouseEnter = (
    event: Upcoming,
    eventPosition: { x: number; y: number }
  ) => {
    setHoveredEvent(event);
    setPopupPosition(eventPosition);
  };

  const handleMouseLeave = () => {
    setHoveredEvent(null);
    setPopupPosition({ x: 0, y: 0 });
  };

  return (
    <>
      <div
        className={`pr-[1rem] mb-4 ${
          isMenuOpen ? "pl-[300px]" : "pl-[76px]"
        } duration-500`}
      >
        {" "}
        <div className="-ml-4 p-custom-lg">
          <Breadcrumbs breadcrumbs={["Dashboard"]} icon={dashboardIcon} />
        </div>
        <div className="flex flex-col lg:flex-row gap-[10px]">
          <div className="lg:w-1/2 flex flex-col relative gap-[10px]">
            {/* List Group 1 */}
            <FirstListGroup data={data} />
            {/* List Group 2 */}
            <SecondListGroup data={data} />
          </div>
          <div className="lg:w-1/2 flex flex-col relative">
            {/* Upcoming Events Table */}
            <UpcomingEventsTable
              upcomingEvents={upcomingEvents}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          </div>
        </div>
      </div>
      {hoveredEvent && hoveredEvent.comment && (
        <div
          className="absolute"
          style={{ top: popupPosition.y, left: popupPosition.x }}
        >
          <div className="bg-white border-2 border-1 border-grey-500 p-custom-md shadow-shadow-md rounded-radius-lg relative dark:bg-black">
            <p>Comment: {hoveredEvent.comment}</p>
          </div>
        </div>
      )}
      {loading && <Loader loading={loading} />}
    </>
  );
};

export default Dashboard;
