import FundamentalAddEdit from "../pages/Fundamental/components/fundamentalAddEdit/FundamentalAddEdit";
import NoteAddEdit from "../pages/NoteAddEdit/NoteAddEdit";
import RegulatoryAddEdit from "../pages/RegulatoryAddEdit";
import AlertList from "../pages/AlertsList/AlertsList";
import Dashboard from "../pages/Dashboard/Dashboard";
import ErrorDetails from "../pages/ErrorDetails/ErrorDetails";
import ErrorList from "../pages/ErrorList/ErrorList";
import FileList from "../pages/FileList/FileList";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import FundamentalDetails from "../pages/Fundamental/components/fundamentalDetails/FundamentalDetails";
import FundamentalList from "../pages/Fundamental/FundamentalList";
import NoteDetails from "../pages/NoteDetails/NoteDetails";
import NoteList from "../pages/NoteList/NoteList";
import RegulatoryDetails from "../pages/RegulatoryDetails/RegulatoryDetails";
import Login from "../pages/Auth/Login";
import ResetPassword from "../pages/Auth/ResetPassword";
import ValidateToken from "../pages/Auth/ValidateToken";
import MnaAddEditMiniForm from "../pages/MnaAddEditMiniForm/MnaAddEditMiniForm";
import PasswordReset from "../pages/PasswordReset/PasswordReset";
import DataMigration from "../pages/DataMigration/DataMigration";
import MnaDetails from "../pages/MnaDetails/MnaDetails";
import MnaList from "../pages/MnaList/MnaList";


import RegulatoryList from "../pages/RegulatoryList/RegulatoryList";

interface Route {
  path: string;
  element: React.ComponentType<any>;
}

interface Route {
  path: string;
  element: React.ComponentType<any>;
}

export const protectedRoutes: Route[] = [
  {
    path: "/dashboard",
    element: (props: any) => <Dashboard {...props} />,
  },
  {
    path: "/real-time-entries",
    element: (props: any) => <Dashboard {...props} />,
  },
  {
    path: "/notes",
    element: (props: any) => <NoteList {...props} />,
  },
  {
    path: "/notes/add",
    element: (props: any) => <NoteAddEdit {...props} />,
  },
  {
    path: "/notes/:id",
    element: (props: any) => <NoteDetails {...props} />,
  },
  {
    path: "/notes/:id/edit",
    element: (props: any) => <NoteAddEdit {...props} />,
  },
  {
    path: "/add-mna",
    element: (props: any) => <MnaAddEditMiniForm {...props} />,
  },
  {
    path: "/mnas",
    element: (props: any) => <MnaList {...props} />,
  },
  {
    path: "/mna/:id",
    element: (props: any) => <MnaDetails {...props} />,
  },
  {
    path: "/regulatory-library",
    element: (props: any) => <Dashboard {...props} />,
  },
  {
    path: "/files",
    element: (props: any) => <FileList {...props} />,
  },
  {
    path: "/error",
    element: (props: any) => <ErrorList {...props} />,
  },
  {
    path: "/error/:id",
    element: (props: any) => <ErrorDetails {...props} />,
  },
  {
    path: "/data-migration",
    element: (props: any) => <DataMigration {...props} />,
  },
  {
    path: "/alerts",
    element: (props: any) => <AlertList {...props} />,
  },
  {
    path: "/fundamental",
    element: (props: any) => <FundamentalList {...props} />,
  },
  {
    path: "/add-fundamental",
    element: (props: any) => <FundamentalAddEdit {...props} />,
  },
  {
    path: "/update-fundamental/:id",
    element: (props: any) => <FundamentalAddEdit {...props} />,
  },
  {
    path: "/fundamental/:id",
    element: (props: any) => <FundamentalDetails {...props} />,
  },
  {
    path: "/regulatory",
    element: (props: any) => <RegulatoryList {...props} />,
  },
  {
    path: "/regulatory/add",
    element: (props: any) => <RegulatoryAddEdit {...props} />,
  },
  {
    path: "/regulatory/:id",
    element: (props: any) => <RegulatoryDetails {...props} />,
  },
  {
    path: "/update-regulatory/:id",
    element: (props: any) => <RegulatoryAddEdit {...props} />,
  },
  {
    path: "/password-reset",
    element: (props: any) => <PasswordReset {...props} />,
  }];

export const publicRoutes: Route[] = [
  {
    path: "/",
    element: (props: any) => <Login {...props} />,
  },
  {
    path: "/forgot-password",
    element: (props: any) => <ForgotPassword {...props} />,
  },
  {
    path: "/reset/",
    element: (props: any) => <ValidateToken {...props} />,
  },
  {
    path: "/reset-password",
    element: (props: any) => <ResetPassword {...props} />,
  },
];
