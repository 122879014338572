// React
import React, { useEffect, useState } from "react";
// Components
import EditableField from "../../../components/EditableFields/EditableField";
// Images
import Expand from "../../../assets/svg/expand.svg";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setIsAllowedToExpandProccesRegulatory } from "../../../features/textEditorExpand";

interface ProcessTabProps {
  data: any;
  fetchRegulatoryData: () => void;
  isMenuOpen: boolean;
  isTextEditorExpanded:boolean;
  setTextEditorIsExpanded:React.Dispatch<React.SetStateAction<boolean>>;
}

const ProcessTab: React.FC<ProcessTabProps> = ({
  data,
  fetchRegulatoryData,
  isMenuOpen,
  isTextEditorExpanded,
  setTextEditorIsExpanded
}) => {

  const dispatch = useDispatch();
  const isAllowedToExpandProccesRegulatory = useSelector((state: RootState) => {
    return state.textEditorExpand.isAllowedToExpandProccesRegulatory;
  });

  function handleCancelEdit() {
    dispatch(setIsAllowedToExpandProccesRegulatory(false));
  }

  useEffect(() => {
    return () => {
      handleCancelEdit();
    };
  }, [dispatch]);

  useEffect(() => {
    if (isTextEditorExpanded) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isTextEditorExpanded]);

  const handleFetcRegulatoryData = async () => {
    fetchRegulatoryData();
  };

  return (
    <>
    
      <div
        data-test-id="tab-content-regulatory"
        className=" px-3.5 pt-4  bg-table-light text-blue-500 text-lg-semibold flex items-center justify-between rounded-tl-md rounded-tr-md"
      >
        <span>Process</span>
        {isAllowedToExpandProccesRegulatory ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              const shouldExpand = !isAllowedToExpandProccesRegulatory;
              dispatch(setIsAllowedToExpandProccesRegulatory(shouldExpand));
              setTextEditorIsExpanded((prev: boolean) => !prev);
            }}
          >
            <img src={Expand} alt="expand" />
          </div>
        ) : null}
      </div>
      <div
        className={`${
          isTextEditorExpanded
            ? `fixed bg-white top-[92px] right-[16px] rounded-radius-lg duration-500 transition-[width] px-2 ${
                isMenuOpen ? "w-[calc(100%-316px)] " : "w-[calc(100%-92px)]"
              }   z-[999] `
            : "px-2 pb-2"
        }`}
      >
        <div>
          {isTextEditorExpanded ? (
            <div className="flex justify-between text-left text-lg-medium text-blue-500 pt-4 px-2">
              <span>Process</span>
              <div
                className="cursor-pointer"
                onClick={() => {
                  const shouldExpand = !isAllowedToExpandProccesRegulatory;
                  dispatch(setIsAllowedToExpandProccesRegulatory(shouldExpand));
                  setTextEditorIsExpanded((prev: boolean) => !prev);
                }}
              >
                <img src={Expand} alt="expand" />
              </div>
            </div>
          ) : null}
          <EditableField
            data={data?.process}
            fieldtype="textEditor"
            fieldName="process"
            fetchMnaData={handleFetcRegulatoryData}
            isRegulatory={true}
            isTextEditorExpanded={isTextEditorExpanded}
            setTextEditorIsExpanded={setTextEditorIsExpanded}
            editorFor="regulatory"
          />
        </div>
      </div>
    </>
  );
};

export default ProcessTab;
