// React
import React from "react";
// Components
import EditableField from "../../../components/EditableFields/EditableField";

interface CommentaryTabProps {
  data: any;
  fetchRegulatoryData: () => void;
}

const CommentaryTab: React.FC<CommentaryTabProps> = ({
  data,
  fetchRegulatoryData,
}) => {
  const handleFetcRegulatoryData = async () => {
    fetchRegulatoryData();
  };
  return (
    <>
      <div
        data-test-id="tab-content-regulatory"
        className=" px-3.5 pt-4 bg-table-light text-lg-semibold text-blue-500  flex items-center justify-between rounded-tl-md rounded-tr-md"
      >
        Commentary
      </div>
      <div className="px-2 pb-2 w-full">
        <EditableField
          data={data?.commentary}
          fieldtype="text"
          fieldName="commentary"
          // fetchRegulatoryData={fetchRegulatoryData}
          fetchMnaData={handleFetcRegulatoryData}
          isRegulatory={true}
        />
      </div>
    </>
  );
};

export default CommentaryTab;
